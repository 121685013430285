export interface DefaultIndicatorsDto {
    upperPressureMin?: number | '';
    upperPressureMax?: number | '';
    lowerPressureMin?: number | '';
    lowerPressureMax?: number | '';
    pulseMin?: number | '';
    pulseMax?: number | '';
    temperatureMax?: number | '';
}

export const emptyIndicators: DefaultIndicatorsDto = {
    upperPressureMin: 100,
    upperPressureMax: 150,
    lowerPressureMin: 60,
    lowerPressureMax: 100,
    pulseMin: 54,
    pulseMax: 96,
    temperatureMax: 37.2
};
