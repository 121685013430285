import { DateUtils } from "../../helpers/dateUtils";
import { EditHealthCertificateFormDto } from "./editHealthCertificateFormDto";

/**
 * Create/Edit health certificate request data transfer object
 */
export interface EditHealthCertificateDto {
    id?: number | '';
    driverId?: number | null;
    typeId: number | null;
    issued: Date | string | null;
    photoFile: File | null;
}

export const mapToAddHealthCertificate = (formData: EditHealthCertificateFormDto): EditHealthCertificateDto => {
    return {
        id: formData.id || '',
        driverId: formData.driverId,
        typeId: formData.type!.id,
        issued: DateUtils.dateToISO(formData.issued),
        photoFile: formData.certificateFile || null
    };
}