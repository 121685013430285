import React from 'react';
import { Typography, Link } from '@mui/material';
import TmAccept from '../../../icons/TmAccept';

interface AcceptButtonProps {
  onClick: () => void;
  children: string;
  disabled?: boolean;
}


const AcceptButton: React.FC<AcceptButtonProps> = ({ onClick, children, disabled = false }) => {
  const style = disabled ? {
    color: "white",
    backgroundColor: "#cccccc",
    borderColor: "#cccccc",
    pointerEvents: "none", // Prevents click events
    textDecoration: "none",
  } : {
    color: "white",
    backgroundColor: "#5bba64"
  };

  return (
    <Link href="#"
      py={1} px={2} 
      sx={style}
      display="inline-flex"
      fontWeight="bold"
      underline="none"
      border={1}
      borderRadius={25}
      borderColor="#5bba64"
      onClick={!disabled ? onClick : undefined}
    >
      <TmAccept stroke="white" />
      <Typography fontWeight="bold">&nbsp;&nbsp;{children}</Typography>
    </Link>
  );
}

export default AcceptButton;
