import { Autocomplete, Backdrop, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import TmFullButton from "../../common/buttons/TmFullButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TmEmptyButton from "../../common/buttons/TmEmptyButton";
import { TextFieldFormControl } from "../../common/form/TextFieldFormControl";
import { LookupDto } from "../../../models/common/lookup.dto";
import { useEffect, useState } from "react";
import { terminalsService } from "../../../services/terminalsService";
import ErrorAlert from "../../common/ErrorAlert";
import Spinner from "../../common/Spinner";
import { dispatchersService } from "../../../services/dispatchersService";
import { SearchDispatcherDto } from "../../../models/dispatchers/searchDispatcherDto";
import { departmentsService } from "../../../services/departmentsService";
import { DepartmentDetailsDto } from "../../../models/departments/departmentDetailsDto";
import { UserUtils } from "../../../helpers/userUtils";

interface EditDepartmentDialogProps {
    open: boolean;
    isAdding: boolean;
    department?: DepartmentDetailsDto;
    companyId?: number;
    onClose: () => void;
    onSave: () => void;
}

interface FormValues {
    name: string;
    address: string;
    dispatchers: SearchDispatcherDto[];
    terminals: LookupDto[];
}

export const EditDepartmentDialog: React.FC<EditDepartmentDialogProps> = ({ open, isAdding, department, companyId, onClose, onSave }) => {
    const [dispatchers, setDispatchers] = useState<SearchDispatcherDto[]>([]);
    const [terminals, setTerminals] = useState<LookupDto[]>([]);
    const [loadingDispatchers, setLoadingDispatchers] = useState(false);
    const [loadingTerminals, setLoadingTerminals] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const emptyForm = (): FormValues => {
        return {
            name: '',
            address: '',
            dispatchers: [],
            terminals: [],
        };
    }

    const fillForm = (d: DepartmentDetailsDto): FormValues => {
        return {
            name: d.name,
            address: d.address,
            dispatchers: d.dispatchers.map((d) => ({ id: d.id, fio: UserUtils.getFio(d), phone: d.phone || '' })),
            terminals: d.terminals.map((t) => ({ id: t.id, name: t.number })),
        };
    }

    const methods = useForm<FormValues>({
        defaultValues: isAdding && department ? fillForm(department) : emptyForm()
    });

    const { reset, control, watch } = methods;

    useEffect(() => {
        if (open) {
            if (department && !isAdding) {
                reset(fillForm(department));
            } else {
                reset(emptyForm());
            }
        }
    }, [open, department, isAdding, reset]);

    const fetchDispatchers = async () => {
        setLoadingDispatchers(true);

        dispatchersService.search({
            fioWithPhone: '',
            page: 0,
            pageSize: 999
        })
            .then(response => {
                setDispatchers(response.data.data.items);
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoadingDispatchers(false));
    };

    const fetchTerminals = async () => {
        setLoadingTerminals(true);

        terminalsService.search({
            nameWithAddress: '',
            page: 0,
            pageSize: 999
        })
            .then(response => {
                setTerminals(response.data.data.items);
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoadingTerminals(false));
    };

    const onSubmit = (data: FormValues) => {
        setLoading(true);

        const serviceCall = isAdding
            ? departmentsService.add({
                name: data.name,
                address: data.address,
                companyId: companyId!,
                terminalIds: data.terminals.map(t => t.id),
                dispatcherIds: data.dispatchers.map(t => t.id),
            })
            : departmentsService.edit({
                id: department!.id,
                name: data.name,
                address: data.address,
                companyId: companyId!,
                terminalIds: data.terminals.map(t => t.id),
                dispatcherIds: data.dispatchers.map(t => t.id),
            });

        serviceCall
            .then(r => {
                onClose();
                onSave();
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoading(false));
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
    };

    return (
        <Box>
            <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: 3, width: 500 } }}>
                <DialogTitle variant="h6" component="h2" textAlign="center">
                    {isAdding ? "Добавление объекта" : "Редактирование данных объекта"}
                </DialogTitle>
                {error && (
                    <ErrorAlert
                        message={error}
                        onClose={() => setError('')}
                    />
                )}
                <DialogContent>
                    <FormProvider {...methods}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextFieldFormControl
                                    name="name"
                                    label="Название"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldFormControl
                                    name="address"
                                    label="Адрес"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="terminals"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            multiple
                                            options={terminals}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            loading={loadingTerminals}
                                            onOpen={fetchTerminals}
                                            onChange={(_, newValue) => field.onChange(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Терминалы"
                                                    placeholder="Выберите терминалы"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loadingTerminals ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="dispatchers"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            multiple
                                            options={dispatchers}
                                            getOptionLabel={(option) => option.fio}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            loading={loadingDispatchers}
                                            onOpen={fetchDispatchers}
                                            onChange={(_, newValue) => field.onChange(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Диспетчеры"
                                                    placeholder="Выберите диспетчера"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loadingDispatchers ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', mt: 1, mx: 2, mb: 3 }}>
                    <TmEmptyButton onClick={onClose} sx={{ flexGrow: 1 }} >
                        Отмена
                    </TmEmptyButton>
                    <TmFullButton onClick={methods.handleSubmit(onSubmit)} sx={{ flexGrow: 1 }} >
                        Сохранить
                    </TmFullButton>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={loading}
            >
                <Spinner />
            </Backdrop>
        </Box>
    );
};