import { Gender } from "../enums/gender";

export interface UserShortDto {
    id?: number;
    firstName: string;
    lastName: string;
    middleName: string;
    gender: Gender | '';
    birthDate: Date | null;
    email: string;
    phoneNumber: string;
    isDismissed: boolean;
    photoUrl?: string;
    photoFile?: File;
}

export const emptyUser: UserShortDto = {
    firstName: '',
    lastName: '',
    middleName: '',
    gender: '',
    birthDate: null,
    email: '',
    phoneNumber: '',
    isDismissed: false
};