import { CategoryType } from "../models/enums/categoryType";
import { PermissionType } from "../models/enums/permissionType";
import { RoleType } from "../models/enums/roleType";
import { storageService } from "./storageService";
import { jwtDecode, JwtPayload } from 'jwt-decode';

type CustomJwtPayload = JwtPayload & {
    'is-admin'?: string;
    role: string | string[];
};

class PermissionService {
    private decodeToken(): CustomJwtPayload | null {
        const tokenData = storageService.getTokenData();
        if (!tokenData) return null;

        try {
            return jwtDecode<CustomJwtPayload>(tokenData.accessToken);
        } catch (error) {
            console.error("Error decoding token: ", error);
            return null;
        }
    }

    private isAdmin(decodedToken: CustomJwtPayload): boolean {
        const isAdminFlag = decodedToken['is-admin']?.toLowerCase() === 'true';
        const hasAdminRole = Array.isArray(decodedToken.role)
            ? decodedToken.role.includes('admin')
            : decodedToken.role === 'admin';

        return isAdminFlag || hasAdminRole;
    }

    canView(category: CategoryType) {
        const decoded = this.decodeToken();
        if (!decoded) return false;

        return this.isAdmin(decoded) || Object.hasOwnProperty.call(decoded, category);
    }

    hasRole(role: RoleType) {
        const decoded = this.decodeToken();
        if (!decoded) return false;

        const userRoles = decoded.role;
        if (Array.isArray(userRoles)) {
            return userRoles.includes(role);
        }
        return userRoles === role;
    }

    canDo(category: CategoryType, permission: PermissionType) {
        const decoded = this.decodeToken();
        if (!decoded) return false;

        const permissionKey = `${category}.${permission}`;
        return this.isAdmin(decoded) || Object.hasOwnProperty.call(decoded, permissionKey);
    }
}

export const permissionService = new PermissionService();