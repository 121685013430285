import { DepartmentShortDto } from "../departments/departmentShortDto";
import { CompanyDispatcherDto } from "../dispatchers/companyDispatcherDto";

export interface CompanyDto {
    id: number;
    name: string;
    inn: string;
    kpp: string;
    ogrn: string;
    address: string;
    contractStatus: string;
    dispatchers: CompanyDispatcherDto[];
    departments: DepartmentShortDto[];
  }

  export const emptyCompany: CompanyDto = {
    id: 0,
    name: '',
    inn: '',
    kpp: '',
    ogrn: '',
    address: '',
    contractStatus: '',
    dispatchers: [],
    departments: []
  };