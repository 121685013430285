import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography } from '@mui/material';
import { DateUtils } from '../../../helpers/dateUtils';
import { medicalCheckupsService } from '../../../services/medicalCheckupsService';
import { MedicalCheckupResult, MedicalCheckupResultUtil } from '../../../models/enums/medicalCheckupResult';
import { MedicalCheckupShortDto } from '../../../models/medicalCheckups/medicalCheckupShortDto';
import { MedicalCheckupTypeUtil } from '../../../models/enums/medicalCheckupType ';
import MedicalCheckupResultSelect from '../../common/MedicalCheckupResultSelect';
import { DatePickerFilter } from '../../common/DatePickerFilter';
import { MedicalCheckupStatus } from '../../../models/enums/medicalCheckupStatus';

interface DriverMedicalCheckupsHistoryTableProps {
    driverId: number;
}


export const DriverMedicalCheckupsHistoryTable: React.FC<DriverMedicalCheckupsHistoryTableProps> = ({ driverId }) => {
    const [data, setData] = useState<MedicalCheckupShortDto[]>([]);
    const [page, setPage] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const [filter, setFilter] = useState({
        driverId: driverId,
        fromDate: null as Date | null,
        toDate: null as Date | null,
        medicResult: null as MedicalCheckupResult | null,
    });

    useEffect(() => {
        const fetchData = () => {
            medicalCheckupsService.getByFilter({
                driverId: driverId,
                fromDate: DateUtils.toServerString(filter.fromDate), // Dates will be send in UTC!
                toDate: DateUtils.toServerString(filter.toDate),
                medicResult: filter.medicResult,
                statuses: [MedicalCheckupStatus.Verdict, MedicalCheckupStatus.Signing],
                page: page,
                pageSize: rowsPerPage,
            })
                .then(r => {
                    setData(r.data.data.items);
                    setDataCount(r.data.data.count);
                })
                .catch(e => console.log(e));
        };

        fetchData(); // Call it once immediately

        const interval = setInterval(fetchData, 30000); // Set up the refresh interval to 30 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [page, rowsPerPage, filter]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rowsPerPage changes
    };

    const filterChanged = (name: string, value: any) => {
        setFilter({ ...filter, [name]: value });
    };

    function renderCheckupResult(result: MedicalCheckupResult) {
        const strValue = MedicalCheckupResultUtil.getStringValue(result);
        let color = result === MedicalCheckupResult.Rejected ? '#ec4a71' : '#5bba64';
        let backgroundColor = result === MedicalCheckupResult.Rejected ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{backgroundColor: {backgroundColor}, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px"}}>{strValue}</Box>;
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" marginBottom={1}>
                <DatePickerFilter
                    label="Начало периода"
                    name="fromDate"
                    value={filter.fromDate}
                    onChange={(value, name) => filterChanged(name, value)}
                    style={{ width: 200, marginRight: 10, marginTop: 5 }}
                />

                <DatePickerFilter
                    label="Конец периода"
                    name="toDate"
                    value={filter.toDate}
                    onChange={(value, name) => filterChanged(name, value)}
                    style={{ width: 200, marginRight: 10, marginTop: 5 }}
                />
               
                <MedicalCheckupResultSelect
                    name={'medicResult'}
                    value={filter.medicResult !== null ? filter.medicResult : undefined}
                    onChange={(e) => filterChanged(e.target.name, e.target.value)}
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>№</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Дата, время</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Тип осмотра</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Давление</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Пульс</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Алкоголь, мг/л</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Температура</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Результат авто</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ fontWeight: 'bold' }}>Результат медика</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow
                                key={item.id}
                                onClick={() => window.open(`/medical-checkups/${item.id}`)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{DateUtils.toLocal(item.date)}</TableCell>
                                <TableCell>{MedicalCheckupTypeUtil.getStringValue(item.type)}</TableCell>
                                <TableCell>{item.indicators.upperPressure}/{item.indicators.lowerPressure}</TableCell>
                                <TableCell>{item.indicators.pulse}</TableCell>
                                <TableCell>{item.indicators.alcohol}</TableCell>
                                <TableCell>{item.indicators.temperature}</TableCell>
                                <TableCell>{renderCheckupResult(item.autoResult)}</TableCell>
                                <TableCell>{renderCheckupResult(item.medicResult)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event: any, page: number) => setPage(page)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any): string => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
        </Box>
    );
};