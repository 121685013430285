import React, { useEffect, useState } from 'react';
import { Typography, TextField, Grid, DialogContent, Autocomplete, Dialog, DialogActions, DialogTitle, FormGroup, Stack } from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import TmFullButton from '../../../common/buttons/TmFullButton';
import TmEmptyButton from '../../../common/buttons/TmEmptyButton';
import { terminalsService } from '../../../../services/terminalsService';
import { Department } from '../models/department';
import { Terminal } from '../models/terminal';

interface AddExistTerminalModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (terminal: Terminal) => void;
    companyId: number;
    departments: Department[];
    existingTerminalIds: number[];
}

interface FormValues {
    terminal: Terminal | null;
}

export const AddExistTerminalModal: React.FC<AddExistTerminalModalProps> = ({ open, companyId, departments, onClose, onSave, existingTerminalIds }) => {
    const [filter, setFilter] = useState({
        nameWithAddress: '',
    });
    const [options, setOptions] = useState<Terminal[]>([]);

    const methods = useForm<FormValues>({
        defaultValues: {
            terminal: null,
        }
    });

    const { control, handleSubmit, formState: { errors }, reset, setValue } = methods;

    useEffect(() => {
        if (open) {
            reset({ terminal: null }); // Reset the form when the modal opens
        }
    }, [open, reset]);

    useEffect(() => {
        terminalsService.search({
            nameWithAddress: filter.nameWithAddress,
            page: 0,
            pageSize: 10
        })
            .then(r => {
                // Map the search results to Terminal type and filter out already added terminals
                const mappedOptions = r.data.data.items.map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    number: item.number,
                    address: item.address,
                    departments: item.departments.map((d: any) => ({ id: d.id, name: d.name }))
                })) as Terminal[];

                const filteredOptions = mappedOptions.filter(
                    (option: Terminal) => !existingTerminalIds.includes(option.id)
                );

                setOptions(filteredOptions);
            })
            .catch(e => console.log(e));
    }, [filter.nameWithAddress, existingTerminalIds]);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            nameWithAddress: event.target.value,
        });
    };

    const onSubmit = (data: FormValues) => {
        if (data.terminal) {
            const terminalId = data.terminal.id;
            terminalsService.linkToCompany(terminalId, companyId)
                .then(r => {
                    onSave(data.terminal!);
                    onClose();
                })
                .catch(e => console.log(e));

            if (data.terminal.departments.length > 0) {
                const departmentIds = [...new Set(data.terminal.departments.map(d => d.id))];
                terminalsService.linkToDepartments(terminalId, departmentIds)
                    .then()
                    .catch(e => console.log(e));
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: 2, width: 600 } }}>
            <DialogTitle variant="h6" textAlign="center">
                Добавление терминала
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <FormGroup>
                        <Stack spacing={2} direction="column">
                            <Controller
                                name="terminal"
                                control={control}
                                rules={{ required: 'Выберите терминал' }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={options}
                                        getOptionLabel={(option) => `${option.name} (${option.address ?? 'нет адреса'})`}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                                        value={field.value}
                                        onChange={(event, newValue) => field.onChange(newValue)}
                                        noOptionsText="Не найдено"
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            Название <span style={{ fontWeight: 'bold' }}>{option.name}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            Адрес <span style={{ fontWeight: 'bold' }}>{option.address}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ mt: 1 }}
                                                {...params}
                                                label="Название/Адрес"
                                                size="small"
                                                onChange={handleFilterChange}
                                                placeholder="Введите от 3х букв"
                                                error={!!errors.terminal}
                                                helperText={errors.terminal ? errors.terminal.message : ''}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name='terminal.departments'
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        id="departmentIds"
                                        options={departments}
                                        getOptionLabel={(item) => (item?.name ?? "")}
                                        value={departments.filter(department => value?.map(dep => dep.id).includes(department.id))}
                                        onChange={(event, newValue) => {
                                            onChange(newValue.map(department => ({ id: department.id, name: department.name })));
                                        }}
                                        noOptionsText="Не найдено"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Объекты'
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                    </FormGroup>
                </FormProvider>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
                <TmEmptyButton onClick={onClose} sx={{ width: '150px' }}>
                    Отмена
                </TmEmptyButton>
                <TmFullButton onClick={handleSubmit(onSubmit)} sx={{ width: '150px' }}>
                    Сохранить
                </TmFullButton>
            </DialogActions>
        </Dialog>
    );
};
