import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

interface SuccessModalProps {
    open: boolean;
    text: string;
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ open, text, onClose }) => {
    return (
        <Dialog 
        open={open} 
        onClose={onClose} 
        aria-labelledby="success-dialog-title">
            <DialogTitle id="success-dialog-title">{"Успешно"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessModal;
