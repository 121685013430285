import { AddDispatcherDto } from "./addDispatcherDto";
import { EditDispatcherDto } from "./editDispatcherDto";
import { DispatcherDetailsDto } from "./dispatcherDetailsDto";
import { Gender } from "../enums/gender";

export interface EditDispatcherFormDto {
  id?: number;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  gender: Gender | '';
  isDismissed: boolean;
  companyIds: number[];
  departmentIds?: number[];
  notificationTypeIds?: number[];
  photoFile?: File;
  photoUrl?: string;
}

export const emptyEditDispatcherFormDto = (companyId?: number): EditDispatcherFormDto => {
  return {
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    isDismissed: false,
    companyIds: companyId ? [companyId] : [],
    departmentIds: [],
    notificationTypeIds: [],
    photoFile: undefined,
    photoUrl: undefined,
  }
};

export const mapToAddDispatcherDto = (formData: EditDispatcherFormDto): AddDispatcherDto => {
  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    middleName: formData.middleName,
    email: formData.email!,
    phoneNumber: formData.phoneNumber,
    gender: formData.gender,
    companyIds: formData.companyIds,
    departmentIds: formData.departmentIds!,
    notificationTypeIds: formData.notificationTypeIds,
    photoFile: formData.photoFile,
  }
};

export const mapToEditDispatcherDto = (formData: EditDispatcherFormDto): EditDispatcherDto => {
  return {
    id: formData.id!,
    firstName: formData.firstName,
    lastName: formData.lastName,
    middleName: formData.middleName,
    email: formData.email!,
    phoneNumber: formData.phoneNumber,
    gender: formData.gender,
    isDismissed: formData.isDismissed,
    companyIds: formData.companyIds,
    departmentIds: formData.departmentIds!,
    notificationTypeIds: formData.notificationTypeIds,
    photoFile: formData.photoFile,
    photoUrl: formData.photoUrl,
  }
};

export const mapToEditDispatcherFormDto = (dto: DispatcherDetailsDto): EditDispatcherFormDto => {
  return {
    id: dto.id!,
    firstName: dto.firstName,
    lastName: dto.lastName,
    middleName: dto.middleName,
    email: dto.email!,
    phoneNumber: dto.phoneNumber,
    gender: dto.gender,
    isDismissed: dto.isDismissed,
    companyIds: dto.companies ? dto.companies.map(d => d.id) : [],
    departmentIds: dto.companies
      ? dto.companies.flatMap(c => c.departments.map(d => d.id))
      : [],
    notificationTypeIds: dto.notificationTypes ? dto.notificationTypes.map(d => d.id) : [],
    photoUrl: dto.photoUrl,
  }
};