import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AddCompanyContextType } from './addCompanyContextType';
import { Department } from '../models/department';
import { Terminal } from '../models/terminal';
import { Driver } from '../models/driver';

const AddCompanyContext = createContext<AddCompanyContextType | undefined>(undefined);

export const AddCompanyContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [companyId, setCompanyId] = useState<number | null>(null);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [terminals, setTerminals] = useState<Terminal[]>([]);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    return (
        <AddCompanyContext.Provider value={{ 
            companyId, setCompanyId, 
            departments, setDepartments, 
            drivers, setDrivers,
            terminals, setTerminals, 
            loading, setLoading, 
            error, setError 
        }}>
            {children}
        </AddCompanyContext.Provider>
    );
};

// Custom hook for consuming context
export const useAddCompanyContext = () => {
    const context = useContext(AddCompanyContext);
    if (!context) {
        throw new Error('useAddCompanyContext must be used within a AddCompanyContextProvider');
    }
    return context;
};
