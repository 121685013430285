import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';
import { authService } from '../../services/authService';
import ErrorAlert from '../common/ErrorAlert';
import SuccessModal from '../common/SuccessModal';
import Spinner from '../common/Spinner';

const SetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
    });
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = (data: any) => {

        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            setLoading(true)
            authService.setPassword(token, data.password)
                .then(r => {
                    if (r.data.success) {
                        setShowDialog(true);
                    }
                    else {
                        showAlert(r.data.message);
                    }
                })
                .catch(e => showAlert(e.response.data.message))
                .finally(() => setLoading(false));
        } else {
            showAlert('Отсутствует токен');
        }
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
        setShowError(true);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
        navigate('/login');
    };

    const password = watch('password');

    return (
        <div>
            {loading
                ? <Spinner />
                : (
                    <div>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                label="Пароль"
                                type="password"
                                margin="normal"
                                {...register('password', {
                                    required: 'Пароль не заполнен',
                                    minLength: {
                                        value: 6,
                                        message: 'Пароль должен быть больше 6ти символов',
                                    },
                                })}
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message as ReactNode}
                            />
                            <TextField
                                fullWidth
                                label="Подтверждение пароля"
                                type="password"
                                margin="normal"
                                {...register('confirmPassword', {
                                    validate: value => value === password || 'Пароли не совпадают',
                                })}
                                error={Boolean(errors.confirmPassword)}
                                helperText={errors.confirmPassword?.message as ReactNode}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={!isValid}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Установить пароль
                            </Button>
                            <SuccessModal
                                open={showDialog}
                                text={`Пароль изменён. Выполните вход.`}
                                onClose={handleCloseDialog}
                            />
                        </Box>
                        {showError && (
                            <ErrorAlert
                                message={error}
                                onClose={() => navigate('/login')}
                                autoCloseDuration={10000}
                            />
                        )}
                    </div>
                )}
        </div>
    );
};

export default SetPassword;
