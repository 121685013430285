const TmArrow = (props: any) => {
    if (props.arrowUp) {
        return (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="7" fill={props.fill} fillOpacity="0.15" />
                <path d="M7 10L7 4M7 4L9.5 6.5M7 4L4.5 6.5" stroke={props.stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    } else

        return (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="7" fill={props.fill} fillOpacity="0.15" />
                <path d="M7 4L7 10M7 10L4.5 7.5M7 10L9.5 7.5" stroke={props.stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
}

export default TmArrow



