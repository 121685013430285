import { Tab, styled } from "@mui/material";

interface StyledTabHiddenProps {
    label: string;
  }
  
  const StyledTabHidden = styled((props: StyledTabHiddenProps) => (
    <Tab disableRipple {...props}/>
  ))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
    color: '#9588e8',
    '&.Mui-selected': {
      color: '#9588e8',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#9588e8',
    },
    visibility: "hidden"
  }));

  export default StyledTabHidden;