import { Avatar, Box, Container, CssBaseline, Grid, Link, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Register from '../../components/auth/Register';
import TmSignUpLogo from '../../components/common/labels/TmSignUpLogo';
import TmPurpleLink from '../../components/common/links/TmPurpleLink';
import { useLayoutEffect } from 'react';
import background from '../../images/background.svg';

const SetPasswordPage = () => {
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#9588e8"
        document.body.style.backgroundImage = `url('${background}')`;
        document.body.style.backgroundRepeat = "no-repeat"
        document.body.style.backgroundSize = "cover"
    });
    return (

        <Container component="main" maxWidth="xs" sx={{ backgroundColor: "white", borderRadius: "25px" }}>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    alignItems: 'center',
                }}
            >
                <TmSignUpLogo sx={{ mb: "10px" }} />
                <Typography component="h1" variant="h5">{"Регистрация"}</Typography>
                <Register />
                <Grid item xs>
                    <TmPurpleLink href="/login" variant="body2">
                        Войти
                    </TmPurpleLink>
                </Grid>
            </Box>
        </Container>

    );
};

export default SetPasswordPage;
