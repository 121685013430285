import React from 'react';
import { Controller, FieldErrors, RegisterOptions, useFormContext } from 'react-hook-form';
import { SxProps, TextField, Theme } from '@mui/material';


interface TextFieldFormControlProps {
    name: string;
    label: string;
    required?: boolean;
    style?: React.CSSProperties;
    type?: 'text' | 'number' | 'email' | 'tel';
    maxLength?: number;
    placeholder?: string;
    validationRules?: RegisterOptions;
    sx?: SxProps<Theme>;
}

export const TextFieldFormControl: React.FC<TextFieldFormControlProps> = ({ 
    name, 
    label, 
    required = false, 
    type = 'text', 
    style, 
    maxLength, 
    placeholder,
    validationRules = {},
    sx,
    ...otherProps
 }) => {
    const { control, formState: { errors } } = useFormContext();

    const getNestedError = (name: string): FieldErrors | undefined => {
        return name.split('.').reduce((acc: any, curr: string) => {
            return acc && acc[curr] ? acc[curr] : undefined;
        }, errors);
    };

    const validateNotOnlySpaces = (value: string) => {
        if (typeof value === 'string') {
            return value.trim().length > 0 || 'Поле не может содержать только пробелы';
        }
        return true;
    };
    
    const fieldError = getNestedError(name);
    const isError = Boolean(fieldError);

    const rules = {
        required: required ? 'Обязательное поле' : false,
        ...(required && { validate: validateNotOnlySpaces }),
        maxLength: maxLength ? { value: maxLength, message: `Максимальная длина ${maxLength} символов` } : undefined,
        ...validationRules,
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...field}
                    type={type}
                    label={label}
                    size="small"
                    error={isError}
                    helperText={isError ? (fieldError as any)?.message : ''}
                    required={required}
                    placeholder={placeholder}
                    style={style}
                    fullWidth
                    sx={sx}
                    {...otherProps}
                />
            )}
        />
    );
};