import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { DriverShortDto } from '../../models/drivers/driverShortDto';
import { driversService } from '../../services/driversService';
import { DateUtils } from '../../helpers/dateUtils';
import CompanyAutocomplete from '../companies/CompanyAutocomplete';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import { EditDriverModal } from './edit/EditDriverModal';
import { CategoryType } from '../../models/enums/categoryType';
import { PermissionType } from '../../models/enums/permissionType';
import { ShowForPermission } from '../common/permissions/ShowForPermission';
import TmPlus from '../../icons/TmPlus';
import { ImportDriversDialog } from './ImportDriversDialog';

export const DriversTable = () => {
    const [drivers, setDrivers] = useState<DriverShortDto[]>([]);
    const [page, setPage] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
    const [isAddPopupOpen, setAddPopupOpen] = useState(false);
    const [isImportPopupOpen, setImportPopupOpen] = useState(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        employeeNumber: '',
        fio: '',
        company: null as CompanyShortDto | null,
        hideDismissed: null as boolean | null,
    });

    useEffect(() => {
        fetchDrivers();
        const interval = setInterval(fetchDrivers, 30000); // Set up the refresh interval to 30 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [page, rowsPerPage, filter]);

    const fetchDrivers = () => {
        driversService.getByFilter({
            page: page,
            pageSize: rowsPerPage,
            employeeNumber: filter.employeeNumber,
            fio: filter.fio,
            companyId: filter.company ? filter.company.id : null,
            hideDismissed: filter.hideDismissed,
        })
            .then(r => {
                setDrivers(r.data.data.items);
                setDataCount(r.data.data.count);
            })
            .catch(e => console.log(e));
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rowsPerPage changes
    };

    const handleCheckboxChange = (rowId: number) => {
        const isSelected = selectedRows.includes(rowId);
        if (isSelected) {
            setSelectedRows((prevSelected) => prevSelected.filter((id) => id !== rowId));
        } else {
            setSelectedRows((prevSelected) => [...prevSelected, rowId]);
        }
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value,
        });
        setPage(0);
    };

    const handleAddPopupToggle = () => {
        setAddPopupOpen(!isAddPopupOpen);
    };

    const handleImportPopupToggle = () => {
        setImportPopupOpen(!isImportPopupOpen);
    };

    function handleCompanyChange(newCompany: CompanyShortDto | null): void {
        setFilter({ ...filter, company: newCompany })
    };

    const handleRowClick = (event: React.MouseEvent, driverId: number) => {
        const target = event.target as HTMLElement;
        if (target.closest('input[type="checkbox"]')) {
            // If it's a checkbox, do nothing
            return;
        }

        navigate(`/drivers/${driverId}`);
    };

    function renderDismissed(result: DriverShortDto) {
        const strValue = result.isDismissed ? "Уволен" : "Работает";
        let color = result.isDismissed ? '#ec4a71' : '#5bba64';
        let backgroundColor = result.isDismissed ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{ backgroundColor: { backgroundColor }, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px" }}>{strValue}</Box>;
    };

    return (
        <Box>
            <Grid container pb={1} mt={2.5}>
                <Grid item xs={8} style={{ display: 'flex', alignItems: 'left' }}>
                    <TextField
                        label="Табельный №"
                        name="employeeNumber"
                        value={filter.employeeNumber}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setPage(0)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />
                    <TextField
                        label="ФИО"
                        name="fio"
                        value={filter.fio}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setPage(0)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />
                    <CompanyAutocomplete company={filter.company} onCompanyChange={handleCompanyChange} style={{ width: 200, marginRight: 10 }} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="hideDismissedCheckbox"
                                checked={filter.hideDismissed || false}
                                onChange={(event) => setFilter({ ...filter, hideDismissed: event.target.checked ? event.target.checked : null })}
                                style={{ color: '#7E5EA9' }}
                            />
                        }
                        label="Скрыть уволенных"
                    />
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'right' }}>

                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Add}>
                        <IconButton sx={{ marginRight: 1, minWidth: 250 }} className="list-item" onClick={handleAddPopupToggle}>
                            <TmPlus fill="none" stroke="white" />&nbsp;
                            <Typography fontWeight="bold" pr={1}>
                                &nbsp;Добавить
                            </Typography>
                        </IconButton>
                    </ShowForPermission>

                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Add}>
                        <IconButton className="list-item" onClick={handleImportPopupToggle}>
                            <TmPlus fill="none" stroke="white" />&nbsp;
                            <Typography fontWeight="bold" pr={1}>
                                &nbsp;Импортировать из Excel
                            </Typography>
                        </IconButton>
                    </ShowForPermission>

                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* TMED-94: Временно убираем чекбоксы
                            <TableCell align="center">
                                <Checkbox
                                    id="header-checkbox"
                                    indeterminate={selectedRows.length > 0 && selectedRows.length < drivers.length}
                                    checked={selectedRows.length === drivers.length}
                                    onChange={() => {
                                        if (selectedRows.length === drivers.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(drivers.map((row) => row.id));
                                        }
                                    }}
                                />
                            </TableCell> */}
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Табельный №</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Фамилия</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Имя</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Отчество</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92", textAlign: "center" }}>Дата рождения</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Клиент</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92", textAlign: "center" }}>Кол-во объектов</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {drivers.map((driver) => (
                            <TableRow
                                key={driver.id}
                                onClick={(event) => handleRowClick(event, driver.id)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                {/* <TableCell align="center">
                                    <Checkbox
                                        id={`checkbox-${driver.id}`}
                                        checked={selectedRows.includes(driver.id)}
                                        onChange={() => handleCheckboxChange(driver.id)}
                                    />
                                </TableCell> */}
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.employeeNumber}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.lastName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.firstName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.middleName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">{DateUtils.formatDate(driver.birthDate)}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.companyName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">{driver.departmentsCount}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{renderDismissed(driver)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
            <EditDriverModal open={isAddPopupOpen} onClose={handleAddPopupToggle} onRefresh={fetchDrivers} isAdding={true} />
            <ImportDriversDialog open={isImportPopupOpen} onClose={handleImportPopupToggle} onRefresh={fetchDrivers} />
        </Box>
    );
};