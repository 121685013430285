import React from 'react';
import { Typography, Link, TypographyOwnProps, SxProps, Theme } from '@mui/material';

interface TmPurpleLinkProps {
    href: string;
    children: string;
    variant?: TypographyOwnProps['variant'];
    sx?: SxProps<Theme>;
  }

  
const TmPurpleLink: React.FC<TmPurpleLinkProps> = ({ href, children, variant, sx }) => {
  return (
    <Link href={href}
        display="inline-flex"
        fontWeight="bold"
        underline="none"
        color="#9588e8"
        variant={variant}
        sx={{...sx}}
    ><Typography fontWeight="bold">{children}</Typography></Link>
  );
}

export default TmPurpleLink;
