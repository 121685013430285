export interface DigitalSignatureDto {
    id: string;
    createdAt: string;
    expiredAt: string;
    description: string;
  }

  export const emptyDigitalSignature = {
    id: '',
    createdAt: '',
    expiredAt: '',
    description: ''
  }