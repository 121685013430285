import { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogActions, DialogTitle, Grid, TextField, FormGroup, Autocomplete, MenuItem, Stack, Typography, Backdrop } from '@mui/material';
import { driversService } from '../../services/driversService';
import ErrorAlert from '../common/ErrorAlert';
import TmFullButton from '../common/buttons/TmFullButton';
import TmEmptyButton from '../common/buttons/TmEmptyButton';
import { DepartmentShortDto } from '../../models/departments/departmentShortDto';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import { companiesService } from '../../services/companiesService';
import { FileUploadAreaFormControl } from '../common/form/FileUploadAreaFormControl';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ImportDriversFormDto } from '../../models/drivers/importDriversFormDto';
import Spinner from '../common/Spinner';
import SuccessModal from '../common/SuccessModal';
import { departmentsService } from '../../services/departmentsService';

const acceptableMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

interface ImportDriversDialogProps {
  open: boolean,
  onClose: () => void,
  onRefresh?: () => void,
  onSuccess?: (newDriverIds: number[]) => void,
  companyId?: number;
}

export const ImportDriversDialog = ({ open, onClose, onRefresh, onSuccess, companyId }: ImportDriversDialogProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccesModal, setShowSuccesModal] = useState(false);
  const [succesModalMessage, setSuccesModalMessage] = useState('');
  const [departments, setDepartments] = useState<DepartmentShortDto[]>([]);
  const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
  const methods = useForm<ImportDriversFormDto>({
    defaultValues: {
      companyId: companyId ? companyId : null,
      departmentIds: [],
      excelFile: null,
    },
  });

  useEffect(() => {
    if (open) {
      methods.reset({
        companyId: companyId ? companyId : null,
        departmentIds: [],
        excelFile: null,
      });
      setError('');
    }
  }, [open, methods]);

  useEffect(() => {
    companiesService.search({
      name: companyId ? undefined : '',
      companyIds: companyId ? [companyId] : undefined,
      page: 0,
      pageSize: 999,
    })
      .then(r => setCompanies(r.data.data.items))
      .catch(e => console.log(e));
  }, []);

  const watchedCompanyId = methods.watch("companyId");
  useEffect(() => {
    if (watchedCompanyId) {
      departmentsService
        .get(watchedCompanyId)
        .then(r => setDepartments(r.data.data));
    }
  }, [watchedCompanyId]);

  const onSubmit = (formData: ImportDriversFormDto) => {
    setLoading(true)

    driversService.importDrivers({
      companyId: formData.companyId!,
      departmentIds: formData.departmentIds,
      excelFile: formData.excelFile!,
    })
      .then(r => {
        const newDriverIds = r.data.data;
        setSuccesModalMessage(`Водители успешно импортированы (${newDriverIds.length})`);
        setShowSuccesModal(true);
        if (onSuccess) {
          onSuccess(newDriverIds);
        }
      })
      .catch(e => {
        console.log(e);
        const error = e.response.data.message ?? e.message;
        showAlert(error);
      })
      .finally(() => setLoading(false));
  };

  const onSubmitError = (errors: any) => {
    showAlert("Заполните все обязательные поля");
    console.log('Form Errors:', errors);
  };

  const showAlert = (errorMessage: string = 'Произошла ошибка') => {
    setError(errorMessage);
  };

  const handleCloseSuccesModal = () => {
    setShowSuccesModal(false);
    onClose();
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "25px" } }}>
          {error && (
            <ErrorAlert
              message={error}
              onClose={() => setError('')}
            />
          )}
          <DialogTitle variant='h5' sx={{ textAlign: 'center' }}>Импорт водителей</DialogTitle>
          <DialogContent>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup>
                    <Typography variant='h6'>Клиенты и объекты</Typography>
                    <Stack spacing={2} direction="column">
                      <Controller
                        name='companyId'
                        control={methods.control}
                        rules={{ required: 'Поле обязательно для заполнения' }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            size="small"
                            id="companyId"
                            options={companyId ? [] : companies}
                            getOptionLabel={(item) => (item?.name ?? "")}
                            value={companies.find(company => company.id === value)}
                            onChange={(event, newValue) => {
                              if (!companyId) { // Only allow changes if companyId is not provided
                                onChange(newValue ? newValue.id : null);
                              }
                            }}
                            noOptionsText={companyId ? "" : "Не найдено"}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Клиент'
                                error={Boolean(methods.formState.errors.companyId)}
                                helperText={methods.formState.errors.companyId?.message}
                                placeholder="Введите от 3х букв"
                                required
                                disabled={!!companyId} // Disable if companyId is provided
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                          />
                        )}
                      />
                      <Controller
                        name='departmentIds'
                        control={methods.control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            select
                            label="Объекты"
                            size="small"
                            SelectProps={{
                              multiple: true,
                              renderValue: (value) => {
                                const selected = value as number[];
                                if (selected.length > 2) {
                                  return `${selected.length} выбрано...`;
                                }
                                return selected.map((id) => departments.find(d => d.id === id)?.name).join(', ');
                              },
                            }}
                            error={Boolean(methods.formState.errors.departmentIds)}
                            helperText={methods.formState.errors.departmentIds?.message}
                          >
                            {departments.map((d) => (
                              <MenuItem key={d.id} value={d.id}>
                                {d.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Stack>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <Typography variant='h6'>Файл</Typography>
                    <FileUploadAreaFormControl
                      name={'excelFile'}
                      accept={'.xls,.xlsx'}
                      acceptableMimeTypes={acceptableMimeTypes}
                      required={true}
                      onError={setError}
                      uploadDescription={'Перетащите сюда Excel файл или нажмите, чтобы загрузить'}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', paddingBottom: "2em" }}>
            <TmEmptyButton onClick={onClose} width='45%'>
              Отмена
            </TmEmptyButton>
            <TmFullButton onClick={methods.handleSubmit(onSubmit, onSubmitError)} width='45%'>
              Импорт
            </TmFullButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loading}
      >
        <Spinner />
      </Backdrop>
      <SuccessModal
        open={showSuccesModal}
        text={succesModalMessage}
        onClose={handleCloseSuccesModal}
      />
    </Box>
  );
};