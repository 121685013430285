import { LookupDto } from "../common/lookup.dto";
import { CompanyDto, emptyCompany } from "../companies/companyDto";
import { DriverDto, emptyDriver } from "../drivers/driverDto";
import { MedicalCheckupResult } from "../enums/medicalCheckupResult";
import { MedicalCheckupStatus } from "../enums/medicalCheckupStatus";
import { MedicalCheckupType } from "../enums/medicalCheckupType ";
import { MedicalIndicatorDto, emptyMedicalIndicator } from "../medicalIndicators/medicalIndicatorDto";
import { MedicDto, emptyMedic } from "../medics/MedicDto";
import { TerminalDto, emptyTerminal } from "../terminals/terminalDto";

  export interface MedicalCheckupDto {
    id: number;
    driver: DriverDto;
    company: CompanyDto;
    medic: MedicDto;
    terminal: TerminalDto;
    type: MedicalCheckupType;
    autoResult: MedicalCheckupResult;
    medicResult: MedicalCheckupResult;
    status: MedicalCheckupStatus;
    complaints: string[];
    indicators: MedicalIndicatorDto;
    rejectReasons: LookupDto[];
    notAllowedDescription: string;
    startedAt: string;
    endAt: string;
    videoUrl: string;
    video2Url: string;
    photoUrl: string;
    signatureUrl?: string | null;
    signedDate: string;
    createdDate: string;
  }

  export const emptyMedicalCheckup: MedicalCheckupDto = {
    id: 0,
    driver: emptyDriver, 
    company: emptyCompany,
    medic: emptyMedic, 
    terminal: emptyTerminal,
    type: MedicalCheckupType.PreTrip,
    autoResult: MedicalCheckupResult.New,
    medicResult: MedicalCheckupResult.New,
    status: MedicalCheckupStatus.New,
    complaints: [],
    indicators: emptyMedicalIndicator,
    rejectReasons: [],
    notAllowedDescription: '',
    startedAt: "",
    endAt: "",
    videoUrl: "",
    video2Url: "",
    photoUrl: "",
    signedDate: "",
    createdDate: "",
};
