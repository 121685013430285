import { ROUTES } from "../../constants/routes";
import TmHome from "./../../icons/TmHome";
import TmMedicalCheckups from "./../../icons/TmMedicalCheckups";
import TmWaybill from "./../../icons/TmWaybill";
import TmStructure from "./../../icons/TmStructure";
import TmDirection from "./../../icons/TmDirection";
import TmExit from "./../../icons/TmExit";

export interface MenuItem {
    text: string;
    link?: string;
    isHideButton?: boolean;
    isPrimary?: boolean;
    isDisabled?: boolean;
    children?: MenuItem[];
    iconIndex?: number;
    isActive?: boolean;
    icon?: any;
    isEmptyRoot?: boolean;
}

export const menuItems: MenuItem[] = [
    {
        text: 'Главная',
        link: '/home',
        isPrimary: true,
        isHideButton: false,
        isDisabled: true,
        iconIndex: 0,
        icon: TmHome,
    },
    {
        text: 'Медосмотры',
        isPrimary: true,
        isHideButton: false,
        isDisabled: false,
        children: [
            { text: 'Новые медосмотры', link: ROUTES.medicalCheckups.listOfNew, isDisabled: false, },
            { text: 'История медосмотров', link: ROUTES.medicalCheckups.history, isDisabled: false, },
            { text: 'Журнал медосмотров', link: ROUTES.medicalCheckups.journal, isDisabled: false },
        ],
        iconIndex: 1,
        icon: TmMedicalCheckups,
    },
    {
        text: 'Путевые листы',
        link: '/waybills',
        isPrimary: true,
        isHideButton: false,
        isDisabled: true,
        iconIndex: 2,
        icon: TmWaybill,
    },
    {
        text: 'Структура',
        isPrimary: true,
        isHideButton: false,
        isDisabled: false,
        children: [
            { text: 'Клиенты', link: ROUTES.clients.list, isDisabled: false },
            { text: 'Водители', link: ROUTES.drivers.list, isDisabled: false },
            { text: 'Медицинские работники', link: ROUTES.medics.list, isDisabled: false },
            { text: 'Диспетчеры', link: ROUTES.dispatchers.list, isDisabled: false },
            { text: 'Терминалы', link: ROUTES.terminals.list, isDisabled: false },
        ],
        iconIndex: 3,
        icon: TmStructure,
    },
    {
        text: 'Направления',
        isPrimary: true,
        isHideButton: false,
        isDisabled: true,
        children: [
            { text: 'Новые направления', link: '/directions/new' },
            { text: 'История направлений', link: '/directions/history' },
        ],
        iconIndex: 4,
        icon: TmDirection,
    },
    {
        text: 'Выйти',
        link: '/logout',
        isPrimary: true,
        isHideButton: false,
        iconIndex: 5,
        icon: TmExit,
        isEmptyRoot: true,
    },
];