import { useLayoutEffect } from "react";
import {Layout} from "../../components/common/Layout"
import { MedicalCheckupsHistoryTable } from "../../components/medical-checkups/MedicalCheckupsHistoryTable";

export const MedicalCheckupsHistoryPage = () => {

    const title = "История медосмотров";
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "white"
        document.body.style.backgroundImage = "";
   });
    return (
        <Layout title={title}>
            <MedicalCheckupsHistoryTable/>
        </Layout>
    )
}