import React from 'react';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { permissionService } from '../../../services/permissionService';

type ShowForPermissionProps = {
    catalog: CategoryType;
    permission: PermissionType;
    children: React.ReactNode;
};

export const ShowForPermission: React.FC<ShowForPermissionProps> = ({ catalog, permission, children }) => {
    const isAllowed = permissionService.canDo(catalog, permission);

    return isAllowed ? <>{children}</> : null;
};
