import React, { useEffect, useState } from 'react';
import { Typography, TextField, Grid, DialogContent, Autocomplete, Dialog, DialogActions, DialogTitle, FormGroup, Stack } from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import TmFullButton from '../../../common/buttons/TmFullButton';
import TmEmptyButton from '../../../common/buttons/TmEmptyButton';
import { dispatchersService } from '../../../../services/dispatchersService';
import { Dispatcher } from '../models/dispatcher';

interface AddExistDispatcherModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (dispatcher: Dispatcher) => void;
    companyId: number;
    existingDispatcherIds: number[];
}

interface FormValues {
    dispatcher: Dispatcher | null;
}

export const AddExistDispatcherModal: React.FC<AddExistDispatcherModalProps> = ({ open, companyId, onClose, onSave, existingDispatcherIds }) => {
    const [filter, setFilter] = useState({
        fioWithPhone: '',
    });
    const [options, setOptions] = useState<Dispatcher[]>([]);

    const methods = useForm<FormValues>({
        defaultValues: {
            dispatcher: null,
        },
    });

    const { control, handleSubmit, formState: { errors }, reset } = methods;

    useEffect(() => {
        if (open) {
            reset({ dispatcher: null }); // Reset the form when the modal opens
        }
    }, [open, reset]);

    useEffect(() => {
        if (filter.fioWithPhone.length >= 3) {
            dispatchersService.search({
                fioWithPhone: filter.fioWithPhone,
                page: 0,
                pageSize: 10,
            })
                .then(r => {
                    const filteredOptions = r.data.data.items
                        .filter((item) => !existingDispatcherIds.includes(item.id))
                        .map((item) => ({
                            id: item.id,
                            fullName: item.fio,
                            phone: item.phone,
                        }));

                    setOptions(filteredOptions);
                })
                .catch(e => console.log(e));
        } else {
            setOptions([]);
        }
    }, [filter.fioWithPhone, existingDispatcherIds]);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            fioWithPhone: event.target.value,
        });
    };

    const onSubmit = (data: FormValues) => {
        if (data.dispatcher) {
            const dispatcherId = data.dispatcher.id;
            dispatchersService.linkToCompany(dispatcherId, companyId)
                .then(r => {
                    onSave(data.dispatcher!);
                    onClose();
                })
                .catch(e => console.log(e));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: 2, width: 600 } }}>
            <DialogTitle variant="h6" textAlign="center">
                Добавление диспетчера
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <FormGroup>
                        <Stack spacing={2} direction="column">
                            <Controller
                                name="dispatcher"
                                control={control}
                                rules={{ required: 'Выберите диспетчера' }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={options}
                                        getOptionLabel={(option) => `${option.fullName} (${option.phone ?? 'нет телефона'})`}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id }
                                        value={field.value}
                                        onChange={(event, newValue) => field.onChange(newValue) }
                                        noOptionsText="Не найдено"
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            ФИО <span style={{ fontWeight: 'bold' }}>{option.fullName}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            Телефон <span style={{ fontWeight: 'bold' }}>{option.phone}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ mt: 1 }}
                                                {...params}
                                                label="ФИО/Телефон"
                                                size="small"
                                                onChange={handleFilterChange}
                                                placeholder="Введите от 3х букв"
                                                error={!!errors.dispatcher}
                                                helperText={errors.dispatcher ? errors.dispatcher.message : ''}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                    </FormGroup>
                </FormProvider>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
                <TmEmptyButton onClick={onClose} sx={{ width: '150px' }}>
                    Отмена
                </TmEmptyButton>
                <TmFullButton onClick={handleSubmit(onSubmit)} sx={{ width: '150px' }}>
                    Сохранить
                </TmFullButton>
            </DialogActions>
        </Dialog>
    );
};
