import * as signalR from "@microsoft/signalr";
import { storageService } from "./storageService";

const baseURL = window.__env.apiUrl;
const URL = baseURL + '/signalr-hub';
const FIRST_ATTEMPT_DELAY = 10000;

class SignalrService {
    private connection: signalR.HubConnection;
    static instance: SignalrService;

    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, {
                accessTokenFactory: () => storageService.getTokenData()?.accessToken || ""
            })
            .withAutomaticReconnect(new CustomRetryPolicy())
            .configureLogging(signalR.LogLevel.Error)
            .build();

        this.initializeConnection();
        this.handleConnectionEvents();
    }

    public subscribeEvent = (methodName: string, handler: (args: any) => void) => {
        this.connection.on(methodName, handler);
    };

    public unsubscribeEvent = (methodName: string, handler: (args: any) => void) => {
        this.connection.off(methodName, handler);
    };

    private initializeConnection() {
        this.connection.start()
            .then(() => console.log('WS connected'))
            .catch(err => {
                console.error("Error while establishing WS connection", err);
            });
    }

    private handleConnectionEvents() {
        this.connection.onreconnecting(error => {
            console.warn(`WS reconnecting: ${error}`);
        });

        this.connection.onreconnected(connectionId => {
            console.log(`WS reconnected: ${connectionId}`);
        });

        this.connection.onclose(error => {
            console.error(`WS connection closed: ${error}`);
        });
    }

    public static getInstance(): SignalrService {
        if (!SignalrService.instance)
            SignalrService.instance = new SignalrService();
        return SignalrService.instance;
    }
}
export default SignalrService.getInstance;

class CustomRetryPolicy {
    nextRetryDelayInMilliseconds(retryContext: { previousRetryCount: number; }) {

        const delay = (retryContext.previousRetryCount + 1) * FIRST_ATTEMPT_DELAY;
        console.log(`Attempt number ${retryContext.previousRetryCount + 1}, retrying in ${delay} milliseconds`);
        return delay;
    }
}