class FeatureTogglesService {
    private featureToggles: any;

    constructor() {
        this.featureToggles = (window as any).__env?.featureToggles || {};
    }

    isRegistrationEnabled(): boolean {
        return !!this.featureToggles.registrationEnabled;
    }
}

export const featureTogglesService = new FeatureTogglesService();
