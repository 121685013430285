import React from 'react';
import { Typography, Link, Button, Theme, SxProps } from '@mui/material';

interface TmSaveButtonProps {
    onClick: () => void;
    children: string;
    width?: string;
    disabled?: boolean;
    sx?: SxProps<Theme>;
  }

  
const TmFullButton: React.FC<TmSaveButtonProps> = ({ onClick, children, width = "10%", disabled = false, sx}) => {

    return (
        <Button
        style={{
            border: "1px",
            borderRadius: "25px",
            borderStyle: "solid",
            borderColor: "#9588e8",
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingTop: "0.8em",
            paddingBottom: "0.8em",
        }}
        
        sx={{ 
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "none",
            backgroundColor: "#9588e8",
            ":hover": {
                backgroundColor: "#9588e8",
            },
            ":disabled": {
                backgroundColor: "#e0e0e0 !Important",
                borderColor: "#e0e0e0 !Important",
            },
            color: "white",
            width: width,
            minWidth: "150px",
            ...sx
        }}
        
        disabled={disabled}
        onClick={onClick}><Typography fontWeight="bold">{children}</Typography></Button>
    );
}

export default TmFullButton;
