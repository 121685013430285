import { Layout } from '../../components/common/Layout';
import { DispatcherDetails } from '../../components/dispatchers/details/DispatcherDetails';

  export const DispatcherDetailsPage = () => {

    const title = "Диспетчеры";

    return (
        <Layout title={title}>
            <DispatcherDetails/>
        </Layout>
    )
}