import React from 'react';
import { permissionService } from '../../../services/permissionService';
import { RoleType } from '../../../models/enums/roleType';

type ShowForRolesProps = {
    roles: RoleType[];
    children: React.ReactNode;
};

export const ShowForRoles: React.FC<ShowForRolesProps> = ({ roles, children }) => {
    const isAllowed = roles.some(role => permissionService.hasRole(role));

    return isAllowed ? <>{children}</> : null;
};
