import { Layout } from '../../components/common/Layout';
import { TerminalDetails } from '../../components/terminals/details/TerminalDetails';

  export const TerminalDetailsPage = () => {

    const title = "Терминалы";

    return (
        <Layout title={title}>
            <TerminalDetails/>
        </Layout>
    )
}