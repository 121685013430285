import api from './api';
import {AppResponse} from "../models/common/app-response";
import { MedicTableViewDto } from '../models/medics/medicTableViewDto';
import { PaginatedResponse } from '../models/common/paginated-response';
import { MedicFilter } from '../models/medics/medicFilter';
import { MedicDetailsDto } from '../models/medics/medicDetailsDto';
import { AddMedicDto } from '../models/medics/addMedicDto';
import { FormDataUtils } from '../helpers/formDataUtils';
import { EditMedicDto } from '../models/medics/editMedicDto';

class MedicsService {

    baseUrl: string = "/api/v1/medics";

    getActiveCertificateId = () => {
        return api.get<AppResponse<string>>(`${this.baseUrl}/my/certificate`).then((data) => data.data.data);
    }

    getByFilter = (filter?: MedicFilter) => {
        return api.get<AppResponse<PaginatedResponse<MedicTableViewDto>>>(this.baseUrl, { params: filter });
    }

    getById = (id: number) => {
        return api.get<AppResponse<MedicDetailsDto>>(`${this.baseUrl}/${id}`);
    }

    add = (data: AddMedicDto) => {
        const formData = FormDataUtils.toFormData(data);
        return api.post<AppResponse<number>>(this.baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    edit = (data: EditMedicDto) => {
        const formData = FormDataUtils.toFormData(data);
        return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    dismiss(id: number) {
        return api.patch<AppResponse<number>>(`${this.baseUrl}/${id}/dismiss`);
    }
}

export const medicsService = new MedicsService();