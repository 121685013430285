import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Footer = () => {
    return (
        <Box component="footer" position="static" sx={{ 
            bgcolor: "white", 
            color: "black", 
            
            }}
            ml={32}>
                
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://telemedconnect.ru/">TeleMedConnect: Онлайн Забота о Вашем Здоровье</Link>
                {' '}{new Date().getFullYear()}{'.'}
            </Typography>
    </Box>
);
};

export default Footer;