import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, IconButton } from '@mui/material';
import TmFullButton from '../../../common/buttons/TmFullButton';
import TmPlus from '../../../../icons/TmPlus';
import { ShowForPermission } from '../../../common/permissions/ShowForPermission';
import { CategoryType } from '../../../../models/enums/categoryType';
import { PermissionType } from '../../../../models/enums/permissionType';
import { AddExistTerminalModal } from '../dialogs/AddExistTerminalModal';
import TmEmptyButton from '../../../common/buttons/TmEmptyButton';
import { EditTerminalModal } from '../../../terminals/edit/EditTerminalModal';
import { useAddCompanyContext } from '../context/AddCompanyContextProvider';
import { Terminal } from '../models/terminal';

interface AddTerminalsTabProps {
    onDone: () => void;
    onPrevious: () => void;
}

export const AddTerminalsTab: React.FC<AddTerminalsTabProps> = ({ onDone, onPrevious }) => {
    const { companyId, departments, terminals, setTerminals, setLoading, setError } = useAddCompanyContext();
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);

    const handleTerminalAdded = (existTerminal: Terminal): void => {
        setTerminals(prevTerminals => [
            ...prevTerminals,
            existTerminal
        ]);
    };

    const handleDone = (): void => {
        onDone();
    }

    return (
        <Box sx={{ textAlign: 'center', width: '100%', mx: 'auto' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ textAlign: 'left', mb: 2 }}>
                        Терминалы
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <ShowForPermission catalog={CategoryType.Companies} permission={PermissionType.Add}>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={() => setAddModalOpen(true)}>
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Добавить терминал
                                </Typography>
                            </IconButton>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={() => setCreateModalOpen(true)}>
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Создать новый терминал
                                </Typography>
                            </IconButton>
                        </ShowForPermission>
                    </Box>
                </Grid>
            </Grid>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Серийный номер</TableCell>
                            <TableCell>Объект</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {terminals.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography sx={{ color: '#999999', mt: 3, mb: 3, textAlign: 'center' }} component={'span'}>
                                        У вас пока не добавлен ни один терминал
                                        <br />
                                        <Typography sx={{ fontSize: '0.85rem', textAlign: 'center' }}>
                                            Необходимо добавить хотя бы 1 терминал, чтобы завершить добавление компании
                                        </Typography>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            terminals.map((terminal) => (
                                <TableRow key={terminal.id}>
                                    <TableCell sx={{ fontWeight: "bold" }}>{terminal.name}</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>{terminal.number}</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>{terminal.departments.map(d => d.name).join(', ')}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: 3 }}>
                <TmEmptyButton width="25%" onClick={onPrevious}>
                    Назад
                </TmEmptyButton>
                <TmFullButton width="25%" onClick={handleDone} disabled={terminals.length === 0}>
                    Завершить
                </TmFullButton>
            </Box>

            <AddExistTerminalModal
                open={isAddModalOpen}
                companyId={companyId!}
                departments={departments}
                existingTerminalIds={terminals.map(t => t.id)}
                onClose={() => setAddModalOpen(false)}
                onSave={handleTerminalAdded}
            />

            <EditTerminalModal
                open={isCreateModalOpen}
                onClose={() => setCreateModalOpen(false)}
                isAdding={true}
                companyId={companyId!}
                onAddingSuccess={handleTerminalAdded}
            />
        </Box>
    );
};
