import React, { useState, useEffect, ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Box, Button, TextField, Stack, Portal, Typography, IconButton, } from '@mui/material';
import { EditHealthCertificateFormDto } from '../../models/healthCertificates/editHealthCertificateFormDto';
import { LookupDto } from '../../models/common/lookup.dto';
import { healthCertificateTypesService } from '../../services/healthCertificateTypesService';
import { FileInputFormControl } from '../common/form/FileInputFormControl';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DatePickerFormControl } from '../common/form/DatePickerFormControl';
import { ImagePreviewFormControl } from '../common/form/ImagePreviewFormControl';
import TmFullButton from '../common/buttons/TmFullButton';
import TmEmptyButton from '../common/buttons/TmEmptyButton';
import TmCross from '../../icons/TmCross';


interface AddCertificateDialogProps {
    driverId: number | null;
    open: boolean;
    onClose: () => void;
    onAddCertificate: (certificateData: EditHealthCertificateFormDto) => void;
}

const AddCertificateDialog: React.FC<AddCertificateDialogProps> = ({ driverId, open, onClose, onAddCertificate }) => {
    const [healthCertificateTypes, setHealthCertificateTypes] = useState<LookupDto[]>([]);
    const methods = useForm<EditHealthCertificateFormDto>({
        defaultValues: {
            driverId: driverId,
            type: null,
            issued: null,
            certificateFile: null
        }
    });

    const { control, handleSubmit, reset, formState: { errors } } = methods;

    useEffect(() => {
        healthCertificateTypesService.get()
            .then(response => setHealthCertificateTypes(response.data.data))
            .catch(error => console.error('Error fetching health certificate types:', error));
    }, []);

    useEffect(() => {
        if (open) {
            reset({
                driverId: driverId,
                type: null,
                issued: null,
                certificateFile: null
            });
        }
    }, [open, reset, driverId]);

    const onSubmit = (formData: EditHealthCertificateFormDto) => {
        onAddCertificate(formData);
        onClose();
    };

    const onSubmitError = (errors: any) => {
        console.log('Form submit errors:', errors);
    };

    return (
        <Portal>
            <FormProvider {...methods}>
                <Box component="form">
                    <Dialog
                        open={open}
                        onClose={onClose}
                        PaperProps={{ sx: { minWidth: 500, maxWidth: 900, borderRadius: "25px" } }}
                    >
                        <DialogTitle sx={{ textAlign: 'center' }}>Добавление справки</DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                                zIndex: 10000
                            }}
                            >
                            <TmCross fill="none" stroke="#cccdd5"/>
                        </IconButton>
                        
                        <DialogContent>
                            <Typography variant='h6' fontWeight="bold">Тип справки</Typography>
                            <Stack spacing={2} direction="column" mb={2}>
                                <Controller
                                    name='type'
                                    control={control}
                                    rules={{ required: 'Поле обязательно для заполнения' }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            select
                                            label="Тип справки"
                                            value={value ? value.id : ''}
                                            onChange={(event) => {
                                                const selectedTypeId = parseInt(event.target.value, 10);
                                                const selectedType = healthCertificateTypes.find(type => type.id === selectedTypeId);
                                                onChange(selectedType || null);
                                            }}
                                            size="small"
                                            error={Boolean(errors.type)}
                                            helperText={errors.type?.message as ReactNode}
                                            required
                                            style={{ marginBottom: 10, marginTop: 10 }}
                                        >
                                            <MenuItem value="" disabled>
                                                Выберите тип справки
                                            </MenuItem>
                                            {healthCertificateTypes.map((type) => (
                                                <MenuItem key={type.id} value={type.id}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Stack>
                            <Typography variant='h6' fontWeight="bold" mb="10px">Дата выдачи справки</Typography>
                            <Stack spacing={2} direction="column" mb={2}>
                                <DatePickerFormControl
                                    name="issued"
                                    label={"Дата выдачи"}
                                    required
                                    style={{ marginBottom: 10 }}
                                />
                            </Stack>
                            <Typography variant='h6' fontWeight="bold" mb="10px">Фото справки</Typography>
                            <Stack spacing={2} direction="column">
                                <FileInputFormControl name="certificateFile" required />
                                <ImagePreviewFormControl name="certificateFile" />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                        <Box width="100%" textAlign="center" mb={2}>
                            <TmEmptyButton onClick={onClose} width="45%" sx={{mx: 0.5}} >
                                Отмена
                            </TmEmptyButton>
                            <TmFullButton onClick={handleSubmit(onSubmit, onSubmitError)} width="45%" sx={{mx: 0.5}}>
                                Сохранить
                            </TmFullButton>
                        </Box>
                        </DialogActions>
                    </Dialog>
                </Box>
            </FormProvider >
        </Portal>
    );
};

export default AddCertificateDialog;
