import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Typography } from '@mui/material';
import TmEmptyButton from './buttons/TmEmptyButton';
import TmFullButton from './buttons/TmFullButton';

interface ConfirmDialogProps {
    open: boolean;
    title: string;
    content: React.ReactNode;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, title, content, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "25px" } }}>
            <Box m={2}>
                <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
                <DialogContent>
                    <Typography fontWeight="bold">{content}</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <TmEmptyButton onClick={onClose} sx={{ width: '45%' }}>
                        Нет
                    </TmEmptyButton>
                    <TmFullButton onClick={onConfirm} sx={{ width: '45%' }}>
                        Да
                    </TmFullButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
