import { Layout } from '../../components/common/Layout';
import { DriverDetails } from '../../components/drivers/details/DriverDetails';

  export const DriverDetailsPage = () => {

    const title = "Водители";

    return (
        <Layout title={title}>
            <DriverDetails/>
        </Layout>
    )
}