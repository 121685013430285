import dayjs from 'dayjs';

export const DateUtils = {
  formatDate: (date: Date | string | null, defaultReturnValue: string = '-'): string => {
    if (date) {
      const result = dayjs(date).format('DD.MM.YYYY');
      return result;
    }
    return defaultReturnValue;
  },

  dateToISO: (date: Date | string | null): string => {
    if (date) {
      const result = dayjs(date).format('YYYY-MM-DD');
      return result;
    }
    return '';
  },

  formatTime: (date: Date | string | null, defaultReturnValue: string = '-'): string => {
    if (date) {
      const result = dayjs(date).format('HH:mm:ss');
      return result;
    }
    return defaultReturnValue;
  },

  formatDateTime: (date: Date | string | null, defaultReturnValue: string = '-'): string => {
    if (date) {
      return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
    }
    return defaultReturnValue;
  },

  getYear: (date: Date | string | null, defaultReturnValue: string = '-'): string => {
    if (date) {
      const parsedDate = dayjs(date);
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY');
      }
    }
    return defaultReturnValue;
  },

  getAge: (birthday: Date | string | null): number | string => {
    if (birthday) {
      const parsedBirthday = dayjs(birthday);
      if (parsedBirthday.isValid()) {
        const now = dayjs();
        return now.diff(parsedBirthday, 'year');
      }
    }
    return '-';
  },

  ageToStr(age: number | string): string {
    if (age) {
      let txt;
      let count = Number(age) % 100;
      if (count >= 5 && count <= 20) {
        txt = 'лет';
      } else {
          count = count % 10;
          if (count === 1) {
            txt = 'год';
          } else if (count >= 2 && count <= 4) {
            txt = 'года';
          } else {
            txt = 'лет';
          }
      }
      return age+" "+txt;
    }
    return "";
  },

  isNotExpired: (date: Date | string): boolean => {
    const expirationDate = dayjs(date);
    if (expirationDate.isValid()) {
      return dayjs().isBefore(expirationDate);
    }
    return true;
  },

  toLocal: (date: Date | string): string => {
    const d = new Date();
    const shiftFromUtcInMinutes = d.getTimezoneOffset();
    const result = dayjs(date).add(shiftFromUtcInMinutes, 'minute').format('DD.MM.YYYY');
    return result;
  },

  getTimeZoneShiftHours: (): number => {
    return -new Date().getTimezoneOffset() / 60;
  },

  toServerString: (date: Date | string | null, defaultReturnValue: string = ''): string | null => {
    if (date) {
      const tzShift = DateUtils.getTimeZoneShiftHours();
      const result = dayjs(date).add(tzShift, 'hour').toISOString();
      return result;
    }
    return null;
  },
}