import { Box, Button, TextField } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { authService } from '../../services/authService';
import ErrorAlert from '../common/ErrorAlert';
import SuccessModal from '../common/SuccessModal';
import TmFullLoadingButton from '../common/buttons/TmFullLoadingButton';

const PasswordRecovery = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
    });

    const onSubmit = (data: any) => {
        authService.forgotPassword(data.email)
            .then(r => {
                if (r.data.success) {
                    setEmail(data.email);
                    setShowDialog(true);
                }
                else{
                    showAlert(r.data.message);
                }
            })
            .catch(e => showAlert(e.response.data.message));
    };

    const showAlert = (errorMessage : string = 'Произошла ошибка') => {
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => setShowError(false), 10000);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <TextField
                    fullWidth
                    label="Электронная почта"
                    type="email"
                    margin="normal"
                    {...register('email', {
                        required: 'Электронная почта не заполнена',
                        minLength: {
                            value: 6,
                            message: 'Электронная почта должна быть больше 6ти символов',
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Невалидная электронная почта',
                        },
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message as ReactNode}
                />
                <TmFullLoadingButton
                    type="submit"
                    sx={{ mt: 3, mb: 2 }}
                    loadingPosition="start"
                    width="100%"
                >
                    Изменить пароль
                </TmFullLoadingButton>
                {showError && (
                    <ErrorAlert 
                        message={error} 
                        onClose={() => setShowError(false)} 
                        autoCloseDuration={10000}
                    />
                )}
            </Box>
            <SuccessModal
                open={showDialog}
                text={`Ссылка на установку пароля отправлена на почту ${email}`} 
                onClose={handleCloseDialog} 
            />
        </div>
    );
};

export default PasswordRecovery;
