/**
 * Типы ограничения прав.
 */
export const enum PermissionType {
    // Не определено (ошибочное значение).
    Undefined = "undefined",

    // Чтение каталога.
    ReadCatalog = "read-catalog",

    // Чтение каталога по Id.
    ReadById = "read-by-id",
    
    // Добавление.
    Add = "add",

    // Редактирование.
    Edit = "edit",
    
    // Удаление.
    Delete = "delete",
}