import api from './api';
import {AppResponse} from "../models/common/app-response";
import { CompanyShortDto } from '../models/companies/companyShortDto';
import { PaginatedResponse } from '../models/common/paginated-response';
import { CompanyFilter } from '../models/companies/companyFilter';
import { CompanyTableViewDto } from '../models/companies/companyTableViewDto';
import { AddCompanyDto } from '../models/companies/addCompanyDto';
import { SearchCompanyFilter } from '../models/companies/searchCompanyFilter';
import qs from 'qs';
import { CompanyDetailsDto } from '../models/companies/companyDetailsDto';
import { EditCompanyDto } from '../models/companies/editCompanyDto';

class CompaniesService {

    baseUrl: string = "/api/v1/companies";

    getByFilter = (filter?: CompanyFilter) => {
        return api.get<AppResponse<PaginatedResponse<CompanyTableViewDto>>>(this.baseUrl, { params: filter });
    }

    search = (filter?: SearchCompanyFilter) => {
        return api.get<AppResponse<PaginatedResponse<CompanyShortDto>>>(`${this.baseUrl}/search`, {
            params: filter,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
        });
    }

    getById = (id: number) => {
        return api.get<AppResponse<CompanyDetailsDto>>(`${this.baseUrl}/${id}`);
    } 

    add = (data: AddCompanyDto) => {
        return api.post<AppResponse<number>>(this.baseUrl, data);
    }
    
    edit = (data : EditCompanyDto) => {
        return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, data);
    }
}

export const companiesService = new CompaniesService();