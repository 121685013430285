import { useLayoutEffect } from "react";
import {Layout} from "../../components/common/Layout"
import { TerminalsTable } from "../../components/terminals/TerminalsTable";

export const TerminalsPage = () => {

    const title = "Терминалы";
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "white"
        document.body.style.backgroundImage = "";
   });
    return (
        <Layout title={title}>
            <TerminalsTable/>
        </Layout>
    )
}