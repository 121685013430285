import { useLayoutEffect } from "react";
import {Layout} from "../../components/common/Layout"
import { NewMedicalCheckupsTable } from "../../components/medical-checkups/NewMedicalCheckupsTable";

export const NewMedicalCheckupsPage = () => {

    const title = "Новые медосмотры";
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "white"
        document.body.style.backgroundImage = "";
   });
    return (
        <Layout title={title}>
            <NewMedicalCheckupsTable/>
        </Layout>
    )
}