import api from "./api";
import {storageService} from "./storageService";
import {AppResponse} from "../models/common/app-response";
import {TokenDto} from "../models/auth/token.dto";
import {UserDto} from "../models/users/userDto";
import { DateUtils } from "../helpers/dateUtils";

class AuthService {

    isAuthenticated() : boolean {
        const tokenData = storageService.getTokenData();
        if(tokenData){
            return DateUtils.isNotExpired(tokenData.accessTokenExpiration) && DateUtils.isNotExpired(tokenData.refreshTokenExpiration);
        }
        return false;
    }

    async login(email: string, password: string) {
        const response = await api
            .post<AppResponse<TokenDto>>("/api/v1/accounts/login", {
                email,
                password
            });
        const token = response.data.data;
        if (token) {
            storageService.setTokenData(token);
            const user = await this.getCurrentUser();
            if (user) {
                storageService.setUserData(user);
            }
        }
        return await response.data;
    }

    async getCurrentUser() {
        try {
            let response = await api
                .get<AppResponse<UserDto>>("/api/v1/users/current");
            return response.data.data;
        } catch (e) {
            console.log(e);
        }
    }

    logout() {
        storageService.removeTokenData();
        storageService.removeUserData();
    }

    register(data: any) {
        return api.post("/api/v1/accounts/register", data);
    }

    forgotPassword(email: string) {
        return api.post<AppResponse<string>>(`/api/v1/accounts/forgot-password`, { email });
    }

    setPassword(token: string, password: string) {
        return api.post<AppResponse<string>>(`/api/v1/accounts/set-password`, { token, password });
    }
}

export const authService = new AuthService();