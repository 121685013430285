import api from './api';
import {AppResponse} from "../models/common/app-response";
import { LookupDto } from '../models/common/lookup.dto';

class RolesService {
    get() {
        return api.get<AppResponse<LookupDto[]>>(`/api/v1/roles`);
    }
}

export const rolesService = new RolesService();