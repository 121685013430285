import { Navigate, PathRouteProps, useLocation } from "react-router-dom";
import { appState } from "../../states/AppState";
import { ROUTES } from "../../constants/routes";

interface Props extends PathRouteProps{
    children: JSX.Element;
    redirectRoute?: string;
}

export const GuardRoute = ({children, redirectRoute = ROUTES.auth.login} : Props) => {
    const location = useLocation();
    
    if(appState.isAuthenticated){
        return children;
    }else{
        const redirectUrl = `${redirectRoute}?returnUrl=${encodeURIComponent(location.pathname + location.search)}`;
        return <Navigate to={redirectUrl} replace />;
    }
}