import { Avatar, Box, Container, CssBaseline, Grid, Link, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SetPassword from '../../components/auth/SetPassword';

const SetPasswordPage = () => {

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">{"Установка пароля"}</Typography>
                <SetPassword />
                <Grid item xs>
                    <Link href="/login" variant="body2">
                        Войти
                    </Link>
                </Grid>
            </Box>
        </Container>
    );
};

export default SetPasswordPage;
