import api from './api';
import { AppResponse } from "../models/common/app-response";
import { PaginatedResponse } from '../models/common/paginated-response';
import { FormDataUtils } from '../helpers/formDataUtils';
import { MedicalCheckupShortDto } from '../models/medicalCheckups/medicalCheckupShortDto';
import { MedicalCheckupDto } from '../models/medicalCheckups/medicalCheckupDto';
import { MedicalCheckupFilter } from '../models/medicalCheckups/medicalCheckupFilter';
import { RejectReasonDto } from '../models/medicalCheckups/rejectReasonDto';
import { MedicalCheckupVerdictDto } from '../models/medicalCheckups/medicalCheckupVerdictDto';

class MedicalCheckupsService {

  baseUrl: string = "/api/v1/medical-checkups";

  getByFilter = (filter?: MedicalCheckupFilter) => {
    return api.post<AppResponse<PaginatedResponse<MedicalCheckupShortDto>>>(`${this.baseUrl}/filter`, filter);
  }

  getById = (id: number) => {
    return api.get<AppResponse<MedicalCheckupDto>>(`${this.baseUrl}/${id}`);
  }

  addMedicalCheckup = (data: MedicalCheckupDto) => {
    const formData = FormDataUtils.toFormData(data);

    return api.post<AppResponse<number>>(this.baseUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updateMedicalCheckup = (data: MedicalCheckupDto) => {
    const formData: FormData = FormDataUtils.toFormData(data);

    return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getRejectReasons = () => {
    return api.get<AppResponse<RejectReasonDto[]>>(`${this.baseUrl}/reject-reasons`);
  }

  setVerdict = (id: number, data: MedicalCheckupVerdictDto) => {
    return api.patch<AppResponse<number>>(`${this.baseUrl}/${id}/verdict`, data);
  }

  lock = (id: number) => {
    return api.post<AppResponse<number>>(`${this.baseUrl}/${id}/lock`);
  }

  unlock = (id: number) => {
    return api.post<AppResponse<number>>(`${this.baseUrl}/${id}/unlock`);
  }

  createHash = (id: number) => {
    return api.post<AppResponse<string>>(`${this.baseUrl}/${id}/hash`).then((data) => data.data.data);
  }
}

export const medicalCheckupsService = new MedicalCheckupsService();