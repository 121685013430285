import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import { Login } from '../../components/auth/Login';
import { featureTogglesService } from '../../services/featureTogglesService';
import { useLayoutEffect } from 'react';
import TmPurpleLink from '../../components/common/links/TmPurpleLink';
import TmSignUpLogo from '../../components/common/labels/TmSignUpLogo';
import background from '../../images/background.svg';


const LoginPage = () => {
    const isRegistrationEnabled = featureTogglesService.isRegistrationEnabled();
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#9588e8"
        document.body.style.backgroundImage = `url('${background}')`;
        document.body.style.backgroundRepeat = "no-repeat"
        document.body.style.backgroundSize = "cover"
    });
    return (
        <Container component="main" maxWidth="xs" sx={{ backgroundColor: "white", borderRadius: "25px" }}>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    alignItems: 'center'
                }}
            >
                <TmSignUpLogo />
                <Typography component="h1" variant="h5">{"Вход в личный кабинет"}</Typography>
                <Login />
                <Grid container>
                    <Grid item xs>
                        <TmPurpleLink href="/password-recovery" variant="body2">
                            Забыли пароль?
                        </TmPurpleLink>
                    </Grid>
                    {isRegistrationEnabled && (
                        <Grid item>
                            <TmPurpleLink href="/register" variant="body2">
                                Зарегистрироваться
                            </TmPurpleLink>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Container>
    );
};

export default LoginPage;
