import { Box, Typography, Button } from '@mui/material';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate(ROUTES.drivers.list);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <LocalHospitalIcon sx={{ fontSize: 80, mb: 2, color: '#7E5EA9' }} />
            <Typography variant="h3" gutterBottom>
                404: Страница не найдена
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }}>
                Извините, мы не можем найти страницу, которую вы ищете.
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleBackToHome}>
                На главную
            </Button>
        </Box>
    );
};

export default NotFoundPage;
