import { PhotoCamera } from '@mui/icons-material';
import { FormHelperText, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';


interface FileInputFormControlProps {
    name: string;
    accept?: string;
    required?: boolean;
    icon?: ReactNode;
    textColor?: string;
    buttonClassName?: string;
}

export const FileInputFormControl = ({ name, accept = "image/*", required = false, icon = <PhotoCamera fontSize="large" />, textColor = "inherit", buttonClassName }: FileInputFormControlProps) => {
    const { control, formState: { errors } } = useFormContext();

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        onChange: (file: File | null) => void
    ) => {
        const files = event.target.files;
        if (files) {
            onChange(files.length > 0 ? files[0] : null);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            rules={{ required: required && 'Файл обязателен для загрузки' }}
            render={({ field: { onChange, value, ref } }) => (
                <Box>
                    <input
                        id={`${name}-file-input`}
                        type="file"
                        accept={accept}
                        onChange={(event) => handleFileChange(event, onChange)}
                        ref={ref}
                        style={{ display: 'none' }}
                        required={required}
                    />
                    <label htmlFor={`${name}-file-input`}>
                        <IconButton
                            component="span"
                            color="default"
                            className={buttonClassName}
                        >
                            {icon}
                            <Typography component="div" sx={{ ml: 1, color: textColor }}>
                                Загрузить фото
                            </Typography>
                        </IconButton>
                    </label>
                    {errors[name] && (
                        <FormHelperText error>{errors[name]?.message as string}</FormHelperText>
                    )}
                </Box>
            )}
        />
    );
};
