import { AddTerminalDto } from "./addTerminalDto";
import { EditTerminalDto } from "./editTerminalDto";
import { TerminalDetailsDto } from "./terminalDetailsDto";

export interface EditTerminalFormDto {
  id?: number;
  name: string;
  number: string;
  address: string;
  hardwareId?: string;
  description: string;
  companyIds: number[];
  departmentIds: number[];
  isNotActive: boolean;
}

export const emptyEditTerminalFormDto = (companyId?: number): EditTerminalFormDto => {
  return {
  name: '',
  number: '',
  address: '',
  hardwareId: '',
  description: '',
  companyIds: companyId ? [companyId] : [],
  departmentIds: [],
  isNotActive: false,
  }
};

export const mapToAddTerminalDto = (formData: EditTerminalFormDto): AddTerminalDto => {
  return {
    name: formData.name,
    number: formData.number,
    address: formData.address,
    hardwareId: formData.hardwareId!,
    description: formData.description,
    companyIds: formData.companyIds,
    departmentIds: formData.departmentIds!,
    isActive: !formData.isNotActive
  }
};

export const mapToEditTerminalDto = (formData: EditTerminalFormDto): EditTerminalDto => {
  return {
    id: formData.id!,
    name: formData.name,
    number: formData.number,
    address: formData.address,
    hardwareId: formData.hardwareId,
    description: formData.description,
    companyIds: formData.companyIds,
    departmentIds: formData.departmentIds!,
    isActive: !formData.isNotActive
  }
};

export const mapToEditTerminalFormDto = (dto: TerminalDetailsDto): EditTerminalFormDto => {
  return {
    id: dto.id,
    name: dto.name,
    number: dto.number,
    address: dto.address,
    hardwareId: dto.hardwareId,
    description: dto.description,
    companyIds: dto.companies ? dto.companies.map(d => d.id) : [],
    departmentIds: dto.departments ? dto.departments.map(d => d.id) : [],
    isNotActive: !dto.isActive
  }
};