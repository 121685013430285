const TmEllipses = ({ fill = "#000", width = 24, height = 26, stroke = "#10BFFF" }: { fill?: string, width?: number, height?: number, stroke?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 26" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6003 14.559C12.797 17.2257 10.7954 19.3538 9.03817 20.6456C8.15695 21.2934 7.36911 21.7072 6.72355 21.8902C6.07374 22.0743 5.66669 22.0008 5.42044 21.8343C5.17419 21.6678 4.95442 21.3174 4.88331 20.6457C4.81267 19.9785 4.90333 19.0932 5.17636 18.0341C5.72081 15.9223 6.95035 13.2721 8.75362 10.6054C10.5569 7.93871 12.5585 5.81063 14.3157 4.51885C15.1969 3.87103 15.9848 3.4572 16.6303 3.27426C17.2802 3.09012 17.6872 3.16358 17.9335 3.3301C18.1797 3.49662 18.3995 3.84703 18.4706 4.51868C18.5412 5.18593 18.4506 6.07122 18.1775 7.1303C17.6331 9.24217 16.4036 11.8924 14.6003 14.559Z" stroke={stroke} strokeWidth="1.4" />
            <path d="M14.9691 11.1253C16.28 14.0655 17.0288 16.8894 17.1968 19.0639C17.2811 20.1543 17.2161 21.0419 17.0302 21.6866C16.8431 22.3356 16.5656 22.6423 16.2941 22.7634C16.0226 22.8844 15.609 22.8858 15.0012 22.5912C14.3975 22.2986 13.6938 21.7538 12.939 20.9623C11.4338 19.384 9.83374 16.9396 8.52285 13.9995C7.21196 11.0593 6.46315 8.23537 6.29512 6.06094C6.21085 4.97047 6.27588 4.08293 6.46174 3.43821C6.64882 2.78924 6.92631 2.48249 7.19781 2.36144C7.46931 2.24039 7.88294 2.239 8.49071 2.53358C9.0945 2.82623 9.79816 3.37105 10.553 4.16254C12.0581 5.74082 13.6582 8.18519 14.9691 11.1253Z" stroke={stroke} strokeWidth="1.4" />
            <path d="M11.8854 16.1462C8.66797 16.0417 5.80051 15.4823 3.77095 14.684C2.75314 14.2836 1.98308 13.8376 1.48422 13.3888C0.982078 12.9372 0.827287 12.5536 0.836938 12.2565C0.846589 11.9594 1.02595 11.5866 1.55635 11.1685C2.08328 10.7531 2.88066 10.358 3.92231 10.0245C5.9994 9.35962 8.89712 8.98747 12.1146 9.09198C15.332 9.1965 18.1995 9.75593 20.2291 10.5542C21.2469 10.9546 22.0169 11.4006 22.5158 11.8494C23.0179 12.301 23.1727 12.6846 23.1631 12.9817C23.1534 13.2788 22.9741 13.6516 22.4437 14.0697C21.9167 14.4851 21.1193 14.8802 20.0777 15.2137C18.0006 15.8786 15.1029 16.2507 11.8854 16.1462Z" stroke={stroke} strokeWidth="1.4" />
            <circle cx="11.6292" cy="12.773" r="1.71577" fill="#10BFFF" />
        </svg>
    )
}

export default TmEllipses