import { ReactNode, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Footer from "./Footer";
import Header from './Header';
import { Sidebar } from './Sidebar';
import SignalrService from '../../services/signalrService';
import bellSoundPath from '../../assets/sounds/bell.mp3';
import { Avatar, IconButton, Link } from '@mui/material';
import InfoAlert from './InfoAlert';
import { ROUTES } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { SignalrEvents } from "../../models/enums/signalrEvents";

interface AppLayoutProps {
  title: string;
  children: ReactNode;
  hasReturnButton?: boolean;
}

export const Layout: React.FC<AppLayoutProps> = ({ title, children, hasReturnButton }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<ReactNode>(null);
  const [originalDocumentTitle, setDocumentTitle] = useState<string>(document.title);
  const [count, setCount] = useState<number>(0);
  const [blinkIntervalId, setBlinkIntervalId] = useState<NodeJS.Timeout | null>(null);
  const { subscribeEvent, unsubscribeEvent } = SignalrService();

  useEffect(() => {
    subscribeEvent(SignalrEvents.NewMedicalCheckupReceived, onNewMedicalCheckupReceived);

    const onTabVisibilityChange = () => {
      if (!document.hidden) { // user open tab
        stopBlinking();
      } else { // user leave tab
        setCount(0);
      }
    };

    document.addEventListener("visibilitychange", onTabVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onTabVisibilityChange);
      stopBlinking();
      unsubscribeEvent(SignalrEvents.NewMedicalCheckupReceived, onNewMedicalCheckupReceived);
    };
  }, []);

  useEffect(() => {
    if (count > 0) {
      if (document.hidden) {
        startBlinking();
      }
    } else {
      stopBlinking();
    }
  }, [count]);

  const startBlinking = () => {
    if (blinkIntervalId) clearInterval(blinkIntervalId);

    const message = `(${count}) Новые медосмотры`;
    let isShown = true;
    let newBlinkIntervalId = setInterval(() => {
      document.title = isShown ? message : originalDocumentTitle;
      isShown = !isShown;
    }, 1000);
    setBlinkIntervalId(newBlinkIntervalId);
  };

  const stopBlinking = () => {
    setCount(0);

    if (blinkIntervalId) {
      clearInterval(blinkIntervalId);
      setBlinkIntervalId(null);
    }

    document.title = originalDocumentTitle;
  };

  const onNewMedicalCheckupReceived = (medicalCheckupId: number) => {
    const url = ROUTES.medicalCheckups.idPath.replace(':id', String(medicalCheckupId));
    const messageElement = (
      <span>
        <Link href={url} target="_blank" rel="noopener noreferrer">Получен новый медосмотр</Link>
      </span>
    );
    setMessage(messageElement);
    setCount(prevCount => prevCount + 1);
    playSound();
  };

  const playSound = () => {
    const volume = window.__env.settings.volume/100;
    const audio = new Audio(bellSoundPath);
    audio.volume = volume;
    audio.play().catch(error => console.error('Error playing sound:', error));
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      
      <Header title={title} hasReturnButton={hasReturnButton} />
      {message && (
        <InfoAlert
          message={message}
          onClose={() => setMessage(null)}
          style={{ marginLeft: 250 }}
        />
      )}
      <Box display="flex" flexGrow={1}>
        <Sidebar />
        <Box component="main" sx={{
          flexGrow: 1,
          mt: 0.1,
          ml: { sm: 30 },
          p: 1.5,
          marginTop: -2
        }}>
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};