import { CompanyShortDto } from "../companies/companyShortDto";

/**
 *  Represents Terminal info for MedicalCheckup page
 * 
 */
export interface TerminalDto {
  id: number;
  name: string;
  address: string;
  version: string;
  }

  export const emptyTerminal: TerminalDto = {
    id: 0,
    address: '',
    name: '',
    version: "v1"
};