const TmCalendar = (props: any) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 12C2.5 8.22876 2.5 6.34315 3.67157 5.17157C4.84315 4 6.72876 4 10.5 4H14.5C18.2712 4 20.1569 4 21.3284 5.17157C22.5 6.34315 22.5 8.22876 22.5 12V14C22.5 17.7712 22.5 19.6569 21.3284 20.8284C20.1569 22 18.2712 22 14.5 22H10.5C6.72876 22 4.84315 22 3.67157 20.8284C2.5 19.6569 2.5 17.7712 2.5 14V12Z" stroke="#CCCDD5" strokeWidth="1.5"/>
<path d="M7.5 4V2.5" stroke="#CCCDD5" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M17.5 4V2.5" stroke="#CCCDD5" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M3 9H22" stroke="#CCCDD5" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M18.5 17C18.5 17.5523 18.0523 18 17.5 18C16.9477 18 16.5 17.5523 16.5 17C16.5 16.4477 16.9477 16 17.5 16C18.0523 16 18.5 16.4477 18.5 17Z" fill="#CCCDD5"/>
<path d="M18.5 13C18.5 13.5523 18.0523 14 17.5 14C16.9477 14 16.5 13.5523 16.5 13C16.5 12.4477 16.9477 12 17.5 12C18.0523 12 18.5 12.4477 18.5 13Z" fill="#CCCDD5"/>
<path d="M13.5 17C13.5 17.5523 13.0523 18 12.5 18C11.9477 18 11.5 17.5523 11.5 17C11.5 16.4477 11.9477 16 12.5 16C13.0523 16 13.5 16.4477 13.5 17Z" fill="#CCCDD5"/>
<path d="M13.5 13C13.5 13.5523 13.0523 14 12.5 14C11.9477 14 11.5 13.5523 11.5 13C11.5 12.4477 11.9477 12 12.5 12C13.0523 12 13.5 12.4477 13.5 13Z" fill="#CCCDD5"/>
<path d="M8.5 17C8.5 17.5523 8.05228 18 7.5 18C6.94772 18 6.5 17.5523 6.5 17C6.5 16.4477 6.94772 16 7.5 16C8.05228 16 8.5 16.4477 8.5 17Z" fill="#CCCDD5"/>
<path d="M8.5 13C8.5 13.5523 8.05228 14 7.5 14C6.94772 14 6.5 13.5523 6.5 13C6.5 12.4477 6.94772 12 7.5 12C8.05228 12 8.5 12.4477 8.5 13Z" fill="#CCCDD5"/>
</svg>

    )
}

export default TmCalendar