import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import TmCalendar from '../../icons/TmCalendar';


interface DatePickerFilterProps {
    label: string;
    name: string;
    value:  Date | string | null;
    onChange: (newValue: Date | string | null, name: string) => void;
    style?: React.CSSProperties;
}

export const DatePickerFilter: React.FC<DatePickerFilterProps> = ({ label, name, value, onChange, style }) => {
   
    return (
        <LocalizationProvider
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
            dateAdapter={AdapterDayjs}
            adapterLocale="ru"
        >
            <DatePicker
                label={label}
                name={name}
                value={value ? dayjs(value) : null}
                slots={{
                    openPickerIcon: TmCalendar,
                  }}
                onChange={(newValue) => {
                    if(newValue?.isValid()){
                        onChange(newValue ? newValue.toISOString() : null, name);
                    }
                }}
                slotProps={{
                    textField: {
                        size: 'small',
                        style: { ...style },
                    },
                }}
            />
        </LocalizationProvider>
    );
};