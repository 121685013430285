export enum Gender {
  Male = 'Male',
  Female = 'Female',
};

export function getGenderString(gender: Gender | ''): string {

  switch (gender) {
    case Gender.Male:
      return 'Мужской';
    case Gender.Female:
      return 'Женский';
    default:
      return '-';
  }
}