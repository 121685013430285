import React, { useEffect } from 'react';
import { Alert } from '@mui/material';

interface ErrorAlertProps {
    message: string;
    onClose: () => void;
    autoCloseDuration?: number;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, onClose, autoCloseDuration = 10000 }) => {
    useEffect(() => {
        if (autoCloseDuration) {
            const timer = setTimeout(onClose, autoCloseDuration);
            return () => clearTimeout(timer);
        }
    }, [onClose, autoCloseDuration]);

    // Split the message by new line characters and render each line separately
    const messageLines = message.split(';').map((line, index) => (
        <div key={index}>{line}</div>
    ));

    return (
        <Alert
            severity="error"
            onClose={onClose}
            sx={{ mb: 2 }}
        >
            {messageLines}
        </Alert>
    );
};

export default ErrorAlert;
