/**
 * Типы каталогов приложения.
 */
export const enum CategoryType {
    // Не определено (ошибочное значение).
    Undefined = "undefined",

    // Пользователи
    Users = "users",

    // Роли
    Roles = "roles",

    // Компании
    Companies = "companies",

    // Подразделения
    Departments = "departments",

    // Медики
    Medics = "medics",

    // Диспетчеры
    Dispatchers = "dispatchers",

    // Водители
    Drivers = "drivers",

    // Медосмотры
    MedicalCheckups = "medicalcheckups",

    // Меджурналы
    MedicalJournals = "medicaljournals",

    // Терминалы
    Terminals = "terminals",

    // Справки
    HealthCertificates = "healthCertificates",
}