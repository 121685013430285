import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, IconButton } from '@mui/material';
import TmFullButton from '../../../common/buttons/TmFullButton';
import TmPlus from '../../../../icons/TmPlus';
import { ShowForPermission } from '../../../common/permissions/ShowForPermission';
import { CategoryType } from '../../../../models/enums/categoryType';
import { PermissionType } from '../../../../models/enums/permissionType';
import { AddExistDispatcherModal } from '../dialogs/AddExistDispatcherModal';
import { EditDispatcherModal } from '../../../dispatchers/edit/EditDispatcherModal';
import { AddNewDepartmentModal } from '../dialogs/AddNewDepartmentModal';
import { DepartmentDispatcherLinkDto } from '../../../../models/dispatchers/departmentDispatcherLinkDto';
import { useAddCompanyContext } from '../context/AddCompanyContextProvider';
import { Dispatcher } from '../models/dispatcher';
import { dispatchersService } from '../../../../services/dispatchersService';
import { Department } from '../models/department';

interface AddDepartmentsAndDispatchersTabProps {
    onNext: () => void;
}

export const AddDepartmentsAndDispatchersTab: React.FC<AddDepartmentsAndDispatchersTabProps> = ({ onNext }) => {
    const { departments, setDepartments, companyId, setLoading, setError } = useAddCompanyContext();
    const [isDepartmentModalOpen, setDepartmentModalOpen] = useState(false);
    const [isDispatcherModalOpen, setDispatcherModalOpen] = useState(false);
    const [isCreateDispatcherModalOpen, setCreateDispatcherModalOpen] = useState(false);
    const [currentDepartmentId, setCurrentDepartmentId] = useState<number | null>(null);

    const handleDispatcherAdded = (dispatcher: Dispatcher): void => {
        setDepartments((prev) =>
            prev.map((department) =>
                department.id === currentDepartmentId
                    ? { ...department, dispatchers: [...department.dispatchers, dispatcher] }
                    : department
            )
        );
    };

    const handleDepartmentAdded = async (department: Department) => {
        setDepartments((prev) => [
            ...prev,
            department,
        ]);
    };

    const handleOpenDispatcherModal = (departmentId: number) => {
        setCurrentDepartmentId(departmentId);
        setDispatcherModalOpen(true);
    };

    const handleNext = async () => {
        const departmentDispatcherLinks: DepartmentDispatcherLinkDto[] = departments
            .filter(department => department.dispatchers.length > 0)
            .map(department => ({
                departmentId: department.id,
                dispatcherIds: department.dispatchers.map(dispatcher => dispatcher.id),
            }));

        try {
            setLoading(true);

            if (departmentDispatcherLinks.length > 0) {
                await dispatchersService.bulkLinkToDepartments(departmentDispatcherLinks);
            }

            onNext();

        } catch (error) {
            console.error('Error linking dispatchers to departments:', error);

            if (error instanceof Error) {
                setError(error.message || 'Ошибка при привязке диспетчеров к отделам');
            } else {
                setError('Произошла неизвестная ошибка');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ textAlign: 'center', width: '100%', mx: 'auto' }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6" sx={{ textAlign: 'left', mb: 2 }} component="div">
                        Объекты и диспетчеры
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ display: 'inline-block', alignItems: 'center' }}>
                    <ShowForPermission catalog={CategoryType.Companies} permission={PermissionType.Add}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={() => setDepartmentModalOpen(true)}>
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1} component="span">
                                    &nbsp;Добавить объект
                                </Typography>
                            </IconButton>
                        </Box>
                    </ShowForPermission>
                </Grid>
            </Grid>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '15%' }}>Название</TableCell>
                            <TableCell sx={{ width: '15%' }}>Адрес</TableCell>
                            <TableCell sx={{ width: '15%' }}>Диспетчер</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {departments.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography sx={{ color: '#999999', mt: 3, mb: 3, textAlign: 'center' }} component="div">
                                        У вас пока не добавлен ни один объект
                                        <br />
                                        <Typography sx={{ fontSize: '0.85rem', textAlign: 'center' }} component="div">
                                            Необходимо добавить хотя бы 1 объект, чтобы продолжить добавление компании
                                        </Typography>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            departments.map((department) => (
                                <TableRow key={department.id}>
                                    <TableCell sx={{ fontWeight: "bold", verticalAlign: 'top' }}>{department.name}</TableCell>
                                    <TableCell sx={{ fontWeight: "bold", verticalAlign: 'top' }}>{department.address}</TableCell>
                                    <TableCell sx={{ verticalAlign: 'top' }}>
                                        <Box>
                                            {department.dispatchers.map((dispatcher) => (
                                                <Typography key={dispatcher.id} sx={{ mb: 2, fontWeight: "bold" }}>
                                                    {dispatcher.fullName}
                                                </Typography>
                                            ))}
                                            <Box sx={{ display: 'flex' }}>
                                                <IconButton sx={{ display: "flex", mr: 1 }} className="list-item-outline" onClick={() => handleOpenDispatcherModal(department.id)}>
                                                    <TmPlus fill="none" stroke="#9588e8" />&nbsp;
                                                    <Typography fontWeight="bold" pr={1}>
                                                        &nbsp;Добавить
                                                    </Typography>
                                                </IconButton>
                                                <IconButton sx={{ display: "flex", justifyContent: 'flex-end' }} className="list-item-outline" onClick={() => setCreateDispatcherModalOpen(true)}>
                                                    <TmPlus fill="none" stroke="#9588e8" />&nbsp;
                                                    <Typography fontWeight="bold" pr={1}>
                                                        &nbsp;Создать диспетчера
                                                    </Typography>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TmFullButton
                sx={{ marginTop: 2 }}
                onClick={handleNext}
                disabled={departments.length === 0}
            >
                Далее
            </TmFullButton>

            <AddNewDepartmentModal
                open={isDepartmentModalOpen}
                companyId={companyId!}
                onClose={() => setDepartmentModalOpen(false)}
                onSave={handleDepartmentAdded}
            />

            <AddExistDispatcherModal
                open={isDispatcherModalOpen}
                onClose={() => setDispatcherModalOpen(false)}
                onSave={handleDispatcherAdded}
                companyId={companyId!}
                existingDispatcherIds={departments.find(dep => dep.id === currentDepartmentId)?.dispatchers.map(d => d.id) || []}
            />

            <EditDispatcherModal
                open={isCreateDispatcherModalOpen}
                onClose={() => setCreateDispatcherModalOpen(false)}
                onAddingSuccess={handleDispatcherAdded}
                isAdding={true}
                companyId={companyId!}
            />
        </Box>
    );
};
