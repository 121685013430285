import api from './api';
import { AppResponse } from "../models/common/app-response";
import { LookupDto } from '../models/common/lookup.dto';
import { FormDataUtils } from '../helpers/formDataUtils';
import { EditHealthCertificateDto } from '../models/healthCertificates/editHealthCertificateDto';

class HealthCertificateService {

    baseUrl: string = "/api/v1/health-certificates";

    get() {
        return api.get<AppResponse<LookupDto[]>>(this.baseUrl);
    }

    add(data: EditHealthCertificateDto) {
        const formData = FormDataUtils.toFormData(data);
        return api.post<AppResponse<number>>(this.baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    update(id: number, data: EditHealthCertificateDto) {
        const formData = FormDataUtils.toFormData(data);
        return api.put<AppResponse<number>>(this.baseUrl + `/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    delete(id: number) {
        return api.delete<AppResponse<number>>(this.baseUrl + `/${id}`);
    }
}

export const healthCertificateService = new HealthCertificateService();