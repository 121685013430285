export enum MedicalCheckupType {

    // Предрейсовый.
    PreTrip = "PreTrip",

    // Предвахтовый.
    PreShift = "PreShift",

    // Предсменный.
    PreRotational = "PreRotational",

    // Пострейсовый.
    PostTrip = "PostTrip",

    // Поствахтовый.
    PostShift = "PostShift",
    
    // Постсменный.
    PostRotational = "PostRotational"
}

export const MedicalCheckupTypeUtil = {
    getStringValue: (type: MedicalCheckupType): string => {
        switch (type) {
            case MedicalCheckupType.PreTrip:
            return "Предрейсовый";
        case MedicalCheckupType.PreShift:
            return "Предвахтовый";
        case MedicalCheckupType.PreRotational:
            return "Предсменный";
        case MedicalCheckupType.PostTrip:
            return "Послерейсовый";
        case MedicalCheckupType.PostShift:
            return "Послевахтовый";
        case MedicalCheckupType.PostRotational:
            return "Послесменный";
        default:
            return "Неизвестный тип";
        }
    }
}
