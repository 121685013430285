import { computed, makeObservable } from "mobx";
import {storageService} from "../services/storageService";
import { DateUtils } from "../helpers/dateUtils";

export class AppState{

    constructor() {
        makeObservable(this, {
            isAuthenticated: computed
        })        
    }

    get isAuthenticated() : boolean {
        const tokenData = storageService.getTokenData();
        if(tokenData){
            const result = DateUtils.isNotExpired(tokenData.accessTokenExpiration) && DateUtils.isNotExpired(tokenData.refreshTokenExpiration);
            return result;
        }
        return false;
    }
}

export const appState = new AppState();