import { useLayoutEffect } from "react";
import {Layout} from "../../components/common/Layout"
import { DriversTable } from "../../components/drivers/DriversTable"

export const DriversPage = () => {

    const title = "Водители";
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "white"
        document.body.style.backgroundImage = "";
   });
    return (
        <Layout title={title}>
            <DriversTable/>
        </Layout>
    )
}