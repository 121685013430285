/**
 * Defines the signalr events in the system.
 */
export enum SignalrEvents {

    // New medicalcheckup received
    NewMedicalCheckupReceived = "NewMedicalCheckupReceived",

    // Test event
    SomeEventReceived = "SomeEventReceived",
}
