import { Layout } from '../../components/common/Layout';
import { MedicDetails } from '../../components/medics/details/MedicDetails';

  export const MedicDetailsPage = () => {

    const title = "Медицинские работники";

    return (
        <Layout title={title}>
            <MedicDetails/>
        </Layout>
    )
}