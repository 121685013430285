import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import { ExpandLess, ExpandMore, Logout, Settings } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";
import { authService } from "../../services/authService";
import { storageService } from "../../services/storageService";
import { useNavigate } from "react-router-dom";
import { UserDto } from '../../models/users/userDto';
import { ConfirmDialog } from './ConfirmDialog';
import { UserUtils } from '../../helpers/userUtils';
import TmBell from '../../icons/TmBell';
import TmExit from '../../icons/TmExit';
import TmGear from '../../icons/TmGear';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface HeaderProps {
  title: string;
  hasReturnButton?: boolean;
}

const Header = ({ title, hasReturnButton }: HeaderProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState<UserDto>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = storageService.getUserData();
    if (user) {
      setUser(user);
    }
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(undefined);
    setOpen(!open);
  };

  const handleLogout = () => {
    authService.logout();
    navigate('/login');
    setOpenDialog(false);
  };

  return (
    <AppBar position="static" elevation={0} sx={{
      bgcolor: "white",
      color: "black",
      borderBottom: 1,
      borderBottomColor: "#e5e5e9"
    }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>

        {hasReturnButton &&
          <IconButton onClick={() => navigate(-1)} sx={{ ml: 22, mr: 1}}>
            <Avatar sx={{ backgroundColor: "white", border: 1, borderColor: "#9588e8" }}>
              <ChevronLeftIcon sx={{ color: '#9588e8' }} />
            </Avatar>
          </IconButton>
        }

        {hasReturnButton &&
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
        }

        {!hasReturnButton &&
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: 200 }}>
            {title}
          </Typography>
        }

        <Box sx={{ color: "grey" }}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            {/* <Badge badgeContent={17} color="secondary"> */}
            <TmBell />
            {/* </Badge> */}
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              sx={{ bgcolor: deepPurple[500] }}
              src={user?.photoUrl}
            >{UserUtils.getInitials(user!)}</Avatar>
            <Typography component="div" sx={{ ml: 1 }}>
              {UserUtils.getShortFio(user!)}
            </Typography>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="menu-appbar"
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose} disabled>
              <Avatar /> Профиль
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled>
              <TmGear fill="none" stroke="#1C274C">
                <Settings fontSize="small" />
              </TmGear>
              &nbsp;&nbsp;&nbsp;Настройки
            </MenuItem>
            <MenuItem onClick={() => setOpenDialog(true)}>
              <TmExit>
                <Logout fontSize="small" />
              </TmExit>
              &nbsp;&nbsp;&nbsp;Выход
            </MenuItem>
          </Menu>
          <ConfirmDialog
            open={openDialog}
            title="Выход"
            content={
              <>
                Вы действительно хотите выйти?
              </>
            }
            onClose={() => setOpenDialog(false)}
            onConfirm={handleLogout}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;