import axios from "axios";
import { storageService } from "./storageService";
import { AppResponse } from "../models/common/app-response";
import { TokenDto } from "../models/auth/token.dto";
import { ROUTES } from "../constants/routes";


const loginApiUrl = "/api/v1/accounts/login";
const refreshTokenApiUrl = "/api/v1/accounts/refresh-token";

const instance = axios.create({
    baseURL: window.__env.apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

const logout = () => {
    const returnUrl = encodeURIComponent(window.location.pathname + window.location.search);
    storageService.clearStorage();
    window.location.href = `${ROUTES.auth.login}?returnUrl=${returnUrl}`;
};

instance.interceptors.request.use(
    (config) => {
        const token = storageService.getTokenData()?.accessToken;
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {

        const originalConfig = err.config;

        if (err.response && err.response.status === 401 && originalConfig.url !== loginApiUrl) {

            // 1. RefreshToken was expired
            if (originalConfig.url === refreshTokenApiUrl) {
                logout();
                return Promise.reject(new Error(err.message));
            }

            // 2. Access Token was expired
            if (!originalConfig._retry) {

                originalConfig._retry = true;

                const refreshToken = storageService.getTokenData()?.refreshToken;

                if (refreshToken) {
                    await instance.post<AppResponse<TokenDto>>(refreshTokenApiUrl, {
                        refreshToken: refreshToken,
                    })
                        .then(r => {
                            if (r.data.success) {
                                storageService.setTokenData(r.data.data)
                                return instance(originalConfig);
                            } else {
                                logout();
                                return Promise.reject(new Error(r.data.message));
                            }
                        })
                        .catch(e => {
                            logout();
                            return Promise.reject(new Error(e.message));
                        });
                }
            }
        }

        return Promise.reject(err);
    }
);

export default instance;