import api from './api';
import {AppResponse} from "../models/common/app-response";
import { PaginatedResponse } from '../models/common/paginated-response';
import { MedicalJournalFilter } from '../models/medicalCheckups/medicalJournalFilter';
import { MedicalJournalShortDto } from '../models/medicalCheckups/medicalJournalShortDto';
import { CreateMedicalJournalDto } from '../models/medicalCheckups/createMidicalJournalDto';
import { LinkObjectDto } from '../models/common/linkObjectDto';

class MedicalJournalsService {

    baseUrl: string = "/api/v1/medical-journals";

    getByFilter(filter? : MedicalJournalFilter) {
        return api.get<AppResponse<PaginatedResponse<MedicalJournalShortDto>>>(this.baseUrl, { params: filter });
    }

    create(data : CreateMedicalJournalDto) {
        return api.post<AppResponse<number>>(this.baseUrl, data);
    }

    getDownloadLink(id: number) {
        return api.get<AppResponse<LinkObjectDto>>(this.baseUrl + `/${id}/download-link`);
    }
}

export const medicalJournalsService = new MedicalJournalsService();