import { Tab, Tabs, styled } from "@mui/material";
import { useState } from "react";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }
  
  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#9588e8',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#9588e8',
    },
    '& .MuiTabs-flexContainer': {
      border: "0",
      borderBottom: "1px",
      borderStyle: "solid",
      borderColor: "#e5e5e9"
    },
  });
  
  

  export default StyledTabs;
  
