import { CompanyShortWithDepartmentsDto } from "../companies/companyShortWithDepartmentsDto";
import { DepartmentShortDto } from "../departments/departmentShortDto";
import { HealthCertificateDto } from "../healthCertificates/healthCertificate.dto";
import { DefaultIndicatorsDto, emptyIndicators } from "../medicalIndicators/defaultIndicatorsDto";
import { UserShortDto, emptyUser } from "../users/userShortDto";

export interface DriverDto {
  id: number | null;
  user: UserShortDto;
  employeeNumber: string;
  snils: string;
  licenceSerial: string;
  licenceNumber: string;
  licenceIssued: Date | null;
  passportSerial: string;
  passportNumber: string;
  defaultIndicators: DefaultIndicatorsDto;
  photoFile: File | null;
  company: CompanyShortWithDepartmentsDto | null;
  departments: DepartmentShortDto[];
  healthCertificates: HealthCertificateDto[];
}

export const emptyDriver: DriverDto = {
  id: null,
  user: emptyUser,
  employeeNumber: '',
  snils: '',
  licenceSerial: '',
  licenceNumber: '',
  licenceIssued: null,
  passportSerial: '',
  passportNumber: '',
  defaultIndicators: emptyIndicators,
  photoFile: null,
  company: null,
  departments: [],
  healthCertificates: [],
};