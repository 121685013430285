import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CompanyDetailsContextType } from './companyDetailsContextType';
import { CompanyDetailsDto } from '../../../../models/companies/companyDetailsDto';

const CompanyDetailsContext = createContext<CompanyDetailsContextType | undefined>(undefined);

export const CompanyDetailsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [company, setCompany] = useState<CompanyDetailsDto | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    return (
        <CompanyDetailsContext.Provider value={{ 
            company, setCompany, 
            loading, setLoading, 
            error, setError 
        }}>
            {children}
        </CompanyDetailsContext.Provider>
    );
};

// Custom hook for consuming context
export const useCompanyDetailsContext = () => {
    const context = useContext(CompanyDetailsContext);
    if (!context) {
        throw new Error('useCompanyDetailsContext must be used within a CompanyDetailsContextProvider');
    }
    return context;
};
