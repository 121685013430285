import { Layout } from '../../components/common/Layout';
import { DepartmentDetails } from '../../components/departments/details/DepartmentDetails';

  export const DepartmentDetailsPage = () => {

    const title = "Клиенты";

    return (
        <Layout title={title}>
            <DepartmentDetails/>
        </Layout>
    )
}