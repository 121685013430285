import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TmCalendar from '../../../icons/TmCalendar';

interface DatePickerFormControlProps {
    name: string;
    label: string;
    style?: React.CSSProperties;
    format?: string;
    required?: boolean;
}

export const DatePickerFormControl = ({ name, label, style, format, required = false }: DatePickerFormControlProps) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            rules={required ? { required: 'Поле обязательно для заполнения' } : {}}
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <DatePicker
                        format={format}
                        label={label}
                        slots={{
                            openPickerIcon: TmCalendar,
                          }}
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                            if (newValue?.isValid()) {
                                onChange(newValue ? newValue.toISOString() : null);
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                style: { ...style },
                                error: !!error,
                                helperText: error ? error.message : null,
                                required: required
                            }
                        }}
                    />
                </LocalizationProvider>
            )}
        />
    );
};