import { DigitalSignatureDto, emptyDigitalSignature } from "../digitalSignatures/digitalSignatureDto";
import { UserShortDto, emptyUser } from "../users/userShortDto";

export interface MedicDto {
    id: string;
    user: UserShortDto;
    digitalSignature: DigitalSignatureDto;
  }

  export const emptyMedic = {
    id: '',
    user: emptyUser,
    digitalSignature: emptyDigitalSignature
  }