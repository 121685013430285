export const FormDataUtils = {
    toFormData: (data: any): FormData => {
        const formData = new FormData();

        const appendFormData = (key: string, value: any, parentKey?: string) => {

            if (!!!value) {
                return;
            }

            const formDataKey = parentKey ? `${parentKey}.${key}` : key;

            if (value instanceof Date) {
                formData.append(formDataKey, value.toISOString());
            } else if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    appendFormData(`${key}[${index}]`, item);
                });
            } else if (value instanceof File) {
                formData.append(formDataKey, value);
            } else if (typeof value === 'object' && value !== null) {
                Object.keys(value).forEach(subKey => {
                    appendFormData(subKey, value[subKey], formDataKey);
                });
            } else {
                formData.append(formDataKey, String(value));
            }
        };

        Object.keys(data).forEach((key) => {
            appendFormData(key, data[key]);
        });

        return formData;
    }
}