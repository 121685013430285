
export const UserUtils = {

    getFio: (user: { lastName: string; firstName: string; middleName?: string; }) => {
      if(user){
        return `${user.lastName} ${user.firstName} ${user.middleName ? user.middleName : ''}`;
      }
      return ' - ';
    },

    getShortFio: (user: { lastName: string; firstName: string; middleName: string; }) => {
      if(user){
        return `${user.lastName} ${user.firstName[0]}. ${user.middleName ? user.middleName[0] + '.' : ''}`;
      }
      return ' - ';
    },

    getInitials: (user: { lastName: string; firstName: string; middleName: string; }): string => {
        if(user){
          const initials = [user.firstName[0].toUpperCase(), user.lastName[0].toUpperCase()];
          return initials.join('');
        }
        return '';
      }
}