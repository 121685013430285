import React, { ReactNode, useEffect } from 'react';
import { Alert, AlertColor } from '@mui/material';

interface InfoAlertProps {
    message: ReactNode | string;
    severity?: AlertColor;
    onClose: () => void;
    autoCloseDuration?: number;
    style?: React.CSSProperties;
}

const InfoAlert: React.FC<InfoAlertProps> = ({ message, onClose, autoCloseDuration = 10000, severity = 'info', style }) => {
    useEffect(() => {
        if (autoCloseDuration) {
            const timer = setTimeout(onClose, autoCloseDuration);
            return () => clearTimeout(timer);
        }
    }, [onClose, autoCloseDuration]);

    return (
        <Alert
            severity={severity}
            onClose={onClose}
            style={{ ...style }}
        >
            {message}
        </Alert>
    );
};

export default InfoAlert;
