export enum MedicalCheckupStatus {

    // Ожидание данных с терминала
    Draft = "Draft",

    // Новый
    New = "New",

    // Вердикт вынесен
    Verdict = "Verdict",

    // Отправлен на подписание
    Signing = "Signing",

    // Ошибка при подписании
    SigningError = "SigningError"
}

export const MedicalCheckupStatusUtil = {
    getStringValue: (result: MedicalCheckupStatus | null): string => {
        switch (result) {
            case MedicalCheckupStatus.Draft:
                return "Ожидание данных с терминала";
            case MedicalCheckupStatus.New:
                return "Новый";
            case MedicalCheckupStatus.Verdict:
                return "Вердикт вынесен";
            case MedicalCheckupStatus.Signing:
                return "Отправлен на подписание";
            case MedicalCheckupStatus.SigningError:
                return "Ошибка при подписании";
            default:
                return "Неизвестно";
        }
    }
}