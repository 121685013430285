import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Drawer, IconButton, Typography, Icon, Avatar, ListItemButton, ListItemIcon, SvgIcon, Box } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { MedicalServicesOutlined } from '@mui/icons-material';
import { MenuItem, menuItems } from './menuConfig';
import { authService } from '../../services/authService';
import { ConfirmDialog } from './ConfirmDialog';
import { deepPurple } from "@mui/material/colors";

export const Sidebar = () => {
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const [openDialog, setOpenDialog] = useState(false);

    const [opened, setOpened] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
        authService.logout();
        navigate('/login');
        setOpenDialog(false);
    };

    const clearActiveChilds = (items: MenuItem[], clickedItem: MenuItem | null) => {
        if (clickedItem && clickedItem.isPrimary) {
            return;
        }

        items
            .filter(item => !item.isHideButton && !item.isDisabled)
            .map((item) => {
                if (item.children && item.children.length > 0) {
                    item.children
                    .filter(ff => !ff.isPrimary)
                    .map(ch => ch.isActive = false);
                }
            });
    };

   
    const openMenuItem = (items: MenuItem[], link: string) => {
        if (opened) {
            return;
        }

        setOpened(true);
        clearActiveChilds(items, null);

        items
            .filter(item => !item.isHideButton && item.isPrimary && !item.isDisabled)
            .map((itemPrimary) => {
                if (itemPrimary.children && itemPrimary.children.some(s => s.link === link)) {
                    itemPrimary.isActive = true;

                    const child = itemPrimary.children.find(f => f.link === link);

                    if (!!child) {
                        child.isActive = true;
                    }
                }
            });
    };

    const renderMenuItems = (items: MenuItem[], parentIndex = '', mainItems: MenuItem[]): JSX.Element[] => {
        openMenuItem(items, window.location.pathname);
        
        return items
            .filter(item => !item.isHideButton)
            .map((item, index) => {
                const currentIndex: string = parentIndex + index;
                const hasChildren = item.children && item.children.length > 0;
                const handleClick = (props: any) => {
                    
                    if (!item.isDisabled) {
                        clearActiveChilds(mainItems, item);
                        if (!!!item.isEmptyRoot) {
                            item.isActive = !item.isActive;
                        }

                        if (item.link === '/logout') {
                            setOpenDialog(true);
                        } else if (hasChildren) {
                            setOpen(prevOpen => ({
                                ...prevOpen,
                                [currentIndex]: !prevOpen[currentIndex],
                            }));
                        } else if (item.link) {
                            navigate(item.link);
                        }
                    }
                };

                const MyIcon = item.icon;

                return (
                    <React.Fragment key={currentIndex}>
                        
                        <ListItem
                            button
                            onClick={handleClick}
                            disabled={item.isDisabled}
                            className={item.isActive && item.isPrimary ? 'list-item' : ''}
                            sx={{ marginTop: hasChildren || item.isPrimary ? 2 : 0 }}>
                            <ListItemButton className={ !item.isPrimary && item.isActive ? 'list-item-child' : !item.isPrimary && !item.isActive ? 'list-item-child-not-act' : '' }>
                                <ListItemIcon>
                                    <Icon sx={{ ml: 0, mt: 0 }}>{MyIcon != null ? <MyIcon stroke={item.isActive ? "white": null}/> : ''}</Icon>
                                </ListItemIcon>
                              
                                <ListItemText>
                                    <Typography variant="body1" sx={{ ml: -4, mt: 0, fontWeight: item.isPrimary ? 'bold' : 'normal', fontSize: 15 }}>                                    
                                        &nbsp;{item.text}
                                    </Typography>
                                </ListItemText>
                                
                            </ListItemButton>
                            {hasChildren && (item.isActive ? <ExpandLess /> : <ExpandMore />)}
                        </ListItem>
                        {hasChildren && (
                            <Collapse in={item.isActive} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {renderMenuItems(item.children ?? [], `${currentIndex}-`, items)}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                );
            });
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                  backgroundColor: "#F6F6F6",
                  border: "0"
                }
              }}
            variant="permanent"
            anchor="left"
            sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
            }}
        >
            <div>
                <Box display="flex" pl={5} pt={3}>
                    <Avatar sx={{ bgcolor: deepPurple[500] }}><MedicalServicesOutlined /></Avatar>
                    <Typography ml={1} mt={1}>
                        Медосмотры
                    </Typography>
                </Box>
                <List component="nav" style={{padding: 10}}>
                    {renderMenuItems(menuItems, "", menuItems)}
                </List>
                <ConfirmDialog
                    open={openDialog}
                    title="Выход"
                    content={
                        <>
                            Вы действительно хотите выйти?
                        </>
                    }
                    onClose={() => setOpenDialog(false)}
                    onConfirm={handleLogout}
                />
            </div>
        </Drawer>
    );
};
