import React from 'react';
import { Typography, Theme, SxProps, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface TmFullLoadingButtonProps {
  children: string;
  width?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  type?: "submit" | "reset" | "button" | undefined;
  loading?: boolean;
  loadingPosition: 'start' | 'end' | 'center';
}

const TmFullLoadingButton: React.FC<TmFullLoadingButtonProps> = ({ 
  children, 
  width = "10%", 
  disabled = false, 
  sx, 
  type, 
  loading, 
  loadingPosition 
}) => {

  const startIcon = loading && loadingPosition === 'start' ? <CircularProgress size={24} color="inherit" /> : <></>;
  const endIcon = loading && loadingPosition === 'end' ? <CircularProgress size={24} color="inherit" /> : <></>;

  return (
    <LoadingButton
      loadingPosition={loading ? loadingPosition : undefined}
      startIcon={startIcon}
      endIcon={endIcon}
      style={{
        border: "1px",
        borderRadius: "25px",
        borderStyle: "solid",
        borderColor: "#9588e8",
        paddingLeft: "2em",
        paddingRight: "2em",
        paddingTop: "0.8em",
        paddingBottom: "0.8em",
      }}
      sx={{
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: "#9588e8",
        ":hover": {
          backgroundColor: "#9588e8",
        },
        ":disabled": {
          backgroundColor: "#e0e0e0 !Important",
          borderColor: "#e0e0e0 !Important",
        },
        color: "white",
        width: width,
        minWidth: "150px",
        ...sx
      }}
      loading={loading}
      disabled={disabled}
      type={type}
    >
      <Typography fontWeight="bold">{children}</Typography>
    </LoadingButton>
  );
}

export default TmFullLoadingButton;