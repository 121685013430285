import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import TmPlus from '../../../icons/TmPlus';
import { DriverShortDto } from '../../../models/drivers/driverShortDto';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { driversService } from '../../../services/driversService';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import { EditDriverModal } from '../../drivers/edit/EditDriverModal';
import { useCompanyDetailsContext } from './context/CompanyDetailsContextProvider';

export const CompanyDriversTab = () => {
    const { company } = useCompanyDetailsContext();
    const [drivers, setDrivers] = useState<DriverShortDto[]>([]);
    const [page, setPage] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isAddPopupOpen, setAddPopupOpen] = useState(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        employeeNumber: '',
        fio: '',
        hideDismissed: null as boolean | null,
    });

    useEffect(() => {
        fetchDrivers();
        const interval = setInterval(fetchDrivers, 30000); // Set up the refresh interval to 30 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [page, rowsPerPage, filter]);

    const fetchDrivers = () => {
        driversService.getByFilter({
            page: page,
            pageSize: rowsPerPage,
            employeeNumber: filter.employeeNumber,
            fio: filter.fio,
            companyId: company!.id,
            hideDismissed: filter.hideDismissed,
        })
            .then(r => {
                setDrivers(r.data.data.items);
                setDataCount(r.data.data.count);
            })
            .catch(e => console.log(e));
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rowsPerPage changes
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFilter({
            ...filter,
            [name]: value,
        });
        setPage(0);
    };

    const handleAddPopupToggle = () => {
        setAddPopupOpen(!isAddPopupOpen);
    };

    const handleRowClick = (event: React.MouseEvent, driverId: number) => {
        navigate(`/drivers/${driverId}`);
    };

    function renderDismissed(result: DriverShortDto) {
        const strValue = result.isDismissed ? "Уволен" : "Работает";
        let color = result.isDismissed ? '#ec4a71' : '#5bba64';
        let backgroundColor = result.isDismissed ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{ backgroundColor: { backgroundColor }, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px" }}>{strValue}</Box>;
    };

    return (
        <Box>
            <Grid container>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'left' }}>
                    <TextField
                        label="Табельный №"
                        name="employeeNumber"
                        value={filter.employeeNumber}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setPage(0)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />
                    <TextField
                        label="ФИО"
                        name="fio"
                        value={filter.fio}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setPage(0)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="hideDismissedCheckbox"
                                checked={filter.hideDismissed || false}
                                onChange={(event) => setFilter({ ...filter, hideDismissed: event.target.checked ? event.target.checked : null })}
                                style={{ color: '#7E5EA9' }}
                            />
                        }
                        label="Скрыть уволенных"
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'right' }}>

                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Add}>
                        <IconButton sx={{ marginRight: 1, minWidth: 180 }} className="list-item" onClick={handleAddPopupToggle}>
                            <TmPlus fill="none" stroke="white" />&nbsp;
                            <Typography fontWeight="bold" pr={1}>
                                &nbsp;Добавить
                            </Typography>
                        </IconButton>
                    </ShowForPermission>

                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Табельный №</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Фамилия</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Имя</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Отчество</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Статус</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {drivers.map((driver) => (
                            <TableRow
                                key={driver.id}
                                onClick={(event) => handleRowClick(event, driver.id)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.employeeNumber}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.lastName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.firstName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.middleName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{renderDismissed(driver)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
            <EditDriverModal open={isAddPopupOpen} onClose={handleAddPopupToggle} onRefresh={fetchDrivers} isAdding={true} companyId={company!.id}/>
        </Box>
    );
};