const TmEye = (props: any) => {
    return (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <g fill={props.stroke}>
            <path d="m11.9999 16.3299c-2.38998 0-4.32998-1.94-4.32998-4.33 0-2.38998 1.94-4.32998 4.32998-4.32998 2.39 0 4.33 1.94 4.33 4.32998 0 2.39-1.94 4.33-4.33 4.33zm0-7.15998c-1.56 0-2.82998 1.26998-2.82998 2.82998s1.26998 2.83 2.82998 2.83 2.83-1.27 2.83-2.83-1.27-2.82998-2.83-2.82998z"/>
            <path d="m12.0001 21.02c-3.76002 0-7.31002-2.2-9.75002-6.02-1.06-1.65-1.06-4.34 0-6.00002 2.45-3.82 6-6.02 9.75002-6.02 3.75 0 7.3 2.2 9.74 6.02 1.06 1.65002 1.06 4.34002 0 6.00002-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54002c-3.23002 0-6.32002 1.94-8.48002 5.33-.75 1.17002-.75 3.21002 0 4.38002 2.16 3.39 5.25 5.33 8.48002 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38002-2.16-3.39-5.25-5.33-8.48-5.33z"/>
            </g>
        </svg>
    )
}

export default TmEye