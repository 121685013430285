import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/ru';
import dayjs, { Dayjs } from 'dayjs';
import { PickerChangeHandlerContext, DateValidationError } from '@mui/x-date-pickers';
import TmCalendar from '../../icons/TmCalendar';


interface DatePickerLocalizedProps {
    label: string;
    name: string;
    value:  Date | string | null;
    onChange: (newValue: Date | string | null, name: string) => void;
    style?: React.CSSProperties;
}

const DatePickerLocalized: React.FC<DatePickerLocalizedProps> = ({ label, name, value, onChange, style }) => {
    const [pickerValue, setPickerValue] = React.useState<Dayjs | null>(dayjs(value));


    function handleChenged(v: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>): void {
        if(v){
            setPickerValue(v);
            v = v.add(3, 'hour');
            const dt = v.toDate();
            onChange(dt, name)
        }        
    }

    return (
        <LocalizationProvider
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
            dateAdapter={AdapterDayjs}
            adapterLocale="ru"
        >
            <DatePicker
                label={label}
                name={name}
                onChange={handleChenged}      
                slots={{
                    openPickerIcon: TmCalendar,
                  }}
                slotProps={{
                    textField: {
                        size: 'small',
                        style: { ...style },
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePickerLocalized;
