const TmCameraAdd = (props: any) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4923 13.7846H9.49231" stroke="#9588E8" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M12.4923 10.7846L12.4923 16.7846" stroke="#9588E8" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M10.2701 21.7846H14.7145C17.8356 21.7846 19.3961 21.7846 20.5171 21.0492C21.0024 20.7308 21.419 20.3217 21.7433 19.8453C22.4923 18.7447 22.4923 17.2125 22.4923 14.1482C22.4923 11.084 22.4923 9.55182 21.7433 8.45121C21.419 7.97474 21.0024 7.56565 20.5171 7.24729C19.7968 6.77474 18.895 6.60584 17.5143 6.54546C16.8555 6.54546 16.2882 6.05529 16.159 5.42097C15.9652 4.46949 15.1143 3.78461 14.126 3.78461H10.8586C9.87036 3.78461 9.01946 4.4695 8.82564 5.42097C8.69643 6.05529 8.12916 6.54546 7.47031 6.54546C6.08964 6.60584 5.18786 6.77474 4.46755 7.24729C3.98226 7.56565 3.56559 7.97474 3.24133 8.45121C2.49231 9.55182 2.49231 11.084 2.49231 14.1482C2.49231 17.2125 2.49231 18.7447 3.24133 19.8453C3.56559 20.3217 3.98226 20.7308 4.46755 21.0492C5.58855 21.7846 7.14906 21.7846 10.2701 21.7846Z" stroke="#9588E8" strokeWidth="1.5" />
            <path d="M19.4923 10.7846H18.4923" stroke="#9588E8" strokeWidth="1.5" strokeLinecap="round" />
        </svg>

    )
}

export default TmCameraAdd