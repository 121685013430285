import { useLayoutEffect } from "react";
import {Layout} from "../../components/common/Layout"
import { MedicalJournalTable } from "../../components/medical-checkups/MedicalJournalTable";

export const MedicalCheckupsJournalPage = () => {

    const title = "Журнал медосмотров";
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "white"
        document.body.style.backgroundImage = "";
   });
    return (
        <Layout title={title}>
            <MedicalJournalTable/>
        </Layout>
    )
}