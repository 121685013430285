import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton, Box, Avatar, Modal, Paper, Stack, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Spinner from '../../common/Spinner';
import { ROUTES } from '../../../constants/routes';
import { medicsService } from '../../../services/medicsService';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import TmPencil from '../../../icons/TmPencil';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import { MedicDetailsDto } from '../../../models/medics/medicDetailsDto';
import { Gender } from '../../../models/enums/gender';
import maleAvatar from '../../../assets/images/male-avatar.png';
import femaleAvatar from '../../../assets/images/female-avatar.png';
import { EditMedicModal } from '../edit/EditMedicModal';
import TelemedLabel from '../../common/labels/TelemedLabel';
import { ConfirmDialog } from '../../common/ConfirmDialog';


export const MedicDetails: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [medic, setMedic] = useState<MedicDetailsDto>();
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("false");

  // const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const medicId = Number(params.id);

    medicsService.getById(medicId)
      .then(r => setMedic(r.data.data))
      .catch(e => {
        console.error(e);
        if (e.response.status === 404) {
          navigate(ROUTES.auth.notFound);
        }
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  const refreshData = () => {
    if (!params.id) return;

    const medicId = Number(params.id);
    setLoading(true);

    medicsService.getById(medicId)
      .then(r => {
        setMedic(r.data.data);
        setEditPopupOpen(false);
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setImageUrl(medic?.photoUrl || (medic?.gender === Gender.Female ? femaleAvatar : maleAvatar));
  }, [medic]);

  function handleEditPopupToggle(): void {
    setEditPopupOpen(!isEditPopupOpen);
  }

  const handleImage = (value: any) => {
    setImageUrl(value);
    setImageModalOpen(true);
  };

  // const handleDismiss = () => {
  //   setLoading(true);
  //   setOpenConfirmDialog(false);

  //   if (medic) {
  //     medicsService.dismiss(medic.id)
  //       .then(refreshData)
  //       .catch(console.log)
  //       .finally(() => setLoading(false));
  //   }
  // }

  return (
    <Box>
      {loading
        ? <Spinner />
        : (<Box>

          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={2}>
              <IconButton onClick={() => navigate(-1)} sx={{ my: 2 }}>
                <Avatar sx={{ backgroundColor: "white", border: 1, borderColor: "#9588e8" }}>
                  <ChevronLeftIcon sx={{ color: '#9588e8' }} />
                </Avatar>
              </IconButton>
            </Grid>
            <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" align="center">Данные медицинского работника</Typography>
            </Grid>
            <Grid item xs={2} /> {/* Empty Grid item for balancing */}
          </Grid>

          <Paper
            sx={{
              padding: 1,
              borderRadius: '24px',
              border: '1px solid #E0E0E0',
              boxShadow: 'none'
            }}
          >
            <Grid container px={3} pt={1} pb={1}>
              <Grid item md={5} xs={5}>
                <Typography className="ltb">Должность</Typography>
                &nbsp; <Typography className="rtb">Медицинский работник</Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <Typography className="ltb">Статус:&nbsp;</Typography>
                <Typography className="rtb">
                  {medic?.isDismissed ? <DenyLabel>Уволен</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}
                </Typography>
              </Grid>
              <Grid item md={1} xs={1}>
              </Grid>
              <Grid item md={3} xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Box mr={1}>
                  <ShowForPermission catalog={CategoryType.Medics} permission={PermissionType.Delete}>
                    <TmFullButton
                      onClick={() => setOpenConfirmDialog(true)}
                      disabled={medic?.isDismissed}
                    >
                      Уволить
                    </TmFullButton>
                  </ShowForPermission>
                </Box> */}
                <ShowForPermission catalog={CategoryType.Medics} permission={PermissionType.Edit}>
                  <IconButton className="list-item" sx={{ px: "20px" }} onClick={handleEditPopupToggle}>
                    <TmPencil fill="none" stroke="white" />&nbsp;&nbsp;
                    <Typography fontWeight="bold">Редактировать</Typography>
                  </IconButton>
                </ShowForPermission>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            sx={{
              marginTop: 2,
              marginBottom: 2,
              padding: 2,
              borderRadius: '24px',
              boxShadow: 'none',
              border: '1px solid #E0E0E0'
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={3}
                md={2}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start'
                }}
              >
                <Avatar
                  src={imageUrl}
                  alt="Фото медика"
                  onClick={(e) => handleImage(imageUrl)}
                  sx={{
                    width: 195,
                    height: 195,
                    borderRadius: '32.5px',
                    flexShrink: 0,
                    cursor: 'pointer', // Add pointer cursor on hover
                    transition: 'transform 0.2s, box-shadow 0.2s', // Smooth transition for hover effects
                    '&:hover': {
                      transform: 'scale(1.02)', // Slightly scale up on hover
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Add subtle shadow on hover
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                md={8}
                sx={{
                  pl: 1,
                }}
              >
                <Typography variant="h5" fontWeight="bold" mb={2}>Личные данные</Typography>
                <Stack spacing={2} direction="column">
                  <Box display="flex">
                    <Typography variant="body1" sx={{ mr: 1 }}>ФИО</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{`${medic?.lastName} ${medic?.firstName} ${medic?.middleName}`}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" sx={{ mr: 1 }}>Телефон</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{medic?.phoneNumber}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" sx={{ mr: 1 }}>Email</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{medic?.email}</Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <TelemedLabel />
              </Grid>
            </Grid>
          </Paper>

          <Paper
            sx={{
              padding: 2,
              borderRadius: '24px',
              boxShadow: 'none',
              border: '1px solid #E0E0E0'
            }}
          >
            <Typography variant="h5" fontWeight="bold" pb={2}>Клиенты и объекты</Typography>
            <Grid container>
              <Grid container sx={{ minHeight: '38px', alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Typography variant="body1">Клиент</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Объекты</Typography>
                </Grid>
              </Grid>
              {medic?.companies?.map((company, index) => (
                <React.Fragment key={company.id}>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                  </Grid>
                  <Grid container sx={{ minHeight: '38px', alignItems: 'center' }}>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">{company.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">{company.departments.map(dep => dep.name).join(', ')}</Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>

          <EditMedicModal onClose={handleEditPopupToggle} onRefresh={refreshData} isAdding={false} medic={medic} open={isEditPopupOpen} />
        </Box >)}
      <Modal
        className='modal-img'
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        closeAfterTransition
      >
        <img
          src={imageUrl}
          alt='Фото медика'
          style={{ maxHeight: "90%", maxWidth: "90%" }}
        />
      </Modal>
      {/* <ConfirmDialog
        open={openConfirmDialog}
        title="Увольнение медицинского работника"
        content={
          <>
            Вы уверены, что хотите уволить сотрудника
            <Typography variant="body1" align="center" fontWeight="bold">
              {`${medic?.lastName} ${medic?.firstName} ${medic?.middleName}?`}
            </Typography>
          </>
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleDismiss}
      /> */}
    </Box >
  );
};
