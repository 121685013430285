import React, { useMemo, useState, useEffect, ReactElement, cloneElement } from 'react';
import { permissionService } from '../../../services/permissionService';
import { RoleType } from '../../../models/enums/roleType';

type DisableIfNoAnyRoleProps = {
    roles: RoleType[];
    children: ReactElement;
    fallbackDisabled?: boolean;
};

/**
 * DisableIfNoAnyRole component renders its child element and conditionally
 * controls its `disabled` state based on the presence of required roles.
 *
 * @component
 * @example
 * // Example usage:
 * <DisableIfNoAnyRole roles={[RoleType.Admin, RoleType.Editor]}>
 *   <TextField label="Editable for Admin or Editor" />
 * </DisableIfNoAnyRole>
 *
 * @param {RoleType[]} roles - Array of roles that are required to keep the component enabled.
 * @param {ReactElement} children - The input or button element to be controlled based on role access.
 * @param {boolean} [fallbackDisabled=true] - The default state of `disabled` if no required roles are found.
 * @returns {ReactElement} A clone of the child element with the `disabled` state set according to role checks.
 */
export const DisableIfNoAnyRole: React.FC<DisableIfNoAnyRoleProps> = ({
    roles,
    children,
    fallbackDisabled = true,
}) => {
    // State to manage whether the component is disabled based on role checks
    const [isDisabled, setIsDisabled] = useState(fallbackDisabled);

    // Memoized check for user roles to determine if the component should be disabled
    const checkRoles = useMemo(() => {
        try {
            // Check if any of the required roles exist in the user's roles
            return roles.some(role => permissionService.hasRole(role));
        } catch (error) {
            console.error('Error checking roles:', error);
            return false;
        }
    }, [roles]);

    // Update the isDisabled state when the role check result changes
    useEffect(() => {
        setIsDisabled(!checkRoles);
    }, [checkRoles]);

    // Clone the child element and inject the `disabled` prop based on the `isDisabled` state
    const childWithDisabledProp = cloneElement(children, { disabled: isDisabled });

    return childWithDisabledProp;
};
