import { TokenDto } from "../models/auth/token.dto";
import { UserDto } from "../models/users/userDto";

class StorageService {

    private tokenKey: string = "token";
    private userKey: string = "user";

    getTokenData(): TokenDto | null {
        const data = localStorage.getItem(this.tokenKey);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    getUserData(): UserDto | null {
        const data = localStorage.getItem(this.userKey);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    updateLocalAccessToken(accessToken: string) {
        let token = this.getTokenData();
        if (token) {
            token.accessToken = accessToken;
            localStorage.setItem(this.tokenKey, JSON.stringify(token));
        }
    }

    setTokenData(token: TokenDto) {
        localStorage.setItem(this.tokenKey, JSON.stringify(token));
    }

    removeTokenData() {
        localStorage.removeItem(this.tokenKey);
    }


    setUserData(user: UserDto) {
        localStorage.setItem(this.userKey, JSON.stringify(user));
    }

    removeUserData() {
        localStorage.removeItem(this.userKey);
    }

    clearStorage() {
        this.removeTokenData();
        this.removeUserData();
    }
}

export const storageService = new StorageService();