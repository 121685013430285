import React, { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Grid, IconButton, Typography } from '@mui/material';
import Spinner from '../../common/Spinner';
import { CompanyDetailsContextProvider, useCompanyDetailsContext } from './context/CompanyDetailsContextProvider';
import ErrorAlert from '../../common/ErrorAlert';
import StyledTabs from '../../common/tabs/StyledTabs';
import { CompanyMainInfoTab } from './CompanyMainInfoTab';
import { CompanyDriversTab } from './CompanyDriversTab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import StyledTab from '../../common/tabs/StyledTab';
import { companiesService } from '../../../services/companiesService';
import { ROUTES } from '../../../constants/routes';


export const CompanyDetails: React.FC = () => {
  return (
    <CompanyDetailsContextProvider>
      <CompanyDetailsContent />
    </CompanyDetailsContextProvider>
  );
};

export const CompanyDetailsContent: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { setCompany, error, setError, loading, setLoading } = useCompanyDetailsContext();
  const navigate = useNavigate();
  const params = useParams();
  
  useEffect(() => {
    const companyId = Number(params.id);

    companiesService.getById(companyId)
        .then(r => setCompany(r.data.data))
        .catch(e => {
            console.error(e);
            if (e.response.status === 404) {
                navigate(ROUTES.auth.notFound);
            }
        })
        .finally(() => setLoading(false));
}, [params.id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      {error && (
        <ErrorAlert
          message={error}
          onClose={() => setError(null)}
        />
      )}

      <Grid container alignItems="center">
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <IconButton onClick={() => navigate(-1)} sx={{ my: 2 }}>
            <Avatar sx={{ backgroundColor: 'white', border: 1, borderColor: '#9588e8' }}>
              <ChevronLeftIcon sx={{ color: '#9588e8' }} />
            </Avatar>
          </IconButton>
        </Grid>

        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" align="center">
            Данные о клиенте
          </Typography>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>

      <StyledTabs value={currentTab} onChange={handleTabChange} sx={{ textAlign: 'center', mx: 'auto' }}>
        <StyledTab label='Общая информация' />
        <StyledTab label='Водители' />
      </StyledTabs>

      <Box sx={{ padding: 3 }}>
        {currentTab === 0 && <CompanyMainInfoTab />}
        {currentTab === 1 && <CompanyDriversTab />}
      </Box>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <Spinner />
      </Backdrop>

    </Box>
  );
};
