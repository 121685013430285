import api from './api';
import {AppResponse} from "../models/common/app-response";
import { LookupDto } from '../models/common/lookup.dto';

class HealthCertificateTypesService {
    get() {
        return api.get<AppResponse<LookupDto[]>>(`/api/v1/health-certificate-types`);
    }
}

export const healthCertificateTypesService = new HealthCertificateTypesService();