import { MedicalIndicatorsEnum } from "../enums/medicalIndicatorsEnum";

export interface MedicalIndicatorDto {
    upperPressure: number;
    lowerPressure: number;
    pulse: number;
    alcohol: number;
    temperature: number;
    upperPressureParam: MedicalIndicatorsEnum;
    lowerPressureParam: MedicalIndicatorsEnum;
    pulseParam: MedicalIndicatorsEnum;
    alcoholParam: MedicalIndicatorsEnum;
    temperatureParam: MedicalIndicatorsEnum;
  }

  export const emptyMedicalIndicator: MedicalIndicatorDto = {
    upperPressure: 0,
    lowerPressure: 0,
    pulse: 0,
    alcohol: 0,
    temperature: 0,
    upperPressureParam: MedicalIndicatorsEnum.Ok,
    lowerPressureParam: MedicalIndicatorsEnum.Ok,
    pulseParam: MedicalIndicatorsEnum.Ok,
    alcoholParam: MedicalIndicatorsEnum.Ok,
    temperatureParam: MedicalIndicatorsEnum.Ok,
};