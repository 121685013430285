import { Box, CircularProgress, Typography } from '@mui/material';

const Spinner = ({ text = '' }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        color="inherit"
        size={68}
      />
      {text && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Spinner;
