import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface AlertModalProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  text: string;
}


const AlertModal: React.FC<AlertModalProps> = ({ open, handleClose, title = "Ошибка!", text }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Box textAlign="center">
          <Typography id="modal-modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: text }}>
          </Typography>
          <Button onClick={handleClose} variant="contained" color="secondary" sx={{ mt: 2 }}>
            Понятно
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AlertModal;
