import { Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface ImagePreviewProps {
    file?: File | null;
    url?: string | null;
    altText?: string;
    onClick?: () => void;
    onRemove?: () => void;
    caption?: string;
}

const ImagePreview = ({ file, url, altText = 'Preview', onClick, onRemove, caption }: ImagePreviewProps) => {
    const imageUrl = file ? URL.createObjectURL(file) : url;

    const hasImage = imageUrl != null;

    const handleRemove = () => {
        if (onRemove) {
            onRemove();
        }
        if (file) {
            URL.revokeObjectURL(imageUrl!); // Clean up!!
        }
    };

    return (
        <>
            {hasImage && (
                <Box sx={{ maxWidth: '20%', position: 'relative' }}>
                    {imageUrl && (
                        <img src={imageUrl} onClick={onClick} alt={altText} style={{ maxHeight: '170px', borderRadius: "25px" }} />
                    )}
                    {onRemove && (
                        <IconButton
                            onClick={handleRemove}
                            aria-label="Remove image"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundColor: 'white',
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                    {caption && <Typography variant="body2" sx={{ mt: 1 }}>{caption}</Typography>}
                </Box>
            )}
        </>
    );
};

export default ImagePreview;
