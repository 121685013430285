import React from 'react';
import { Box, Grid, FormGroup, Stack, Typography } from '@mui/material';
import TmFullButton from '../../../common/buttons/TmFullButton';
import { TextFieldFormControl } from '../../../common/form/TextFieldFormControl';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { companiesService } from '../../../../services/companiesService';
import { AddCompanyFormDto, mapToAddCompanyDto } from '../../../../models/companies/addCompanyFormDto';
import { useAddCompanyContext } from '../context/AddCompanyContextProvider';

interface AddCompanyTabProps {
    onNext: () => void;
}

export const AddCompanyTab: React.FC<AddCompanyTabProps> = ({ onNext }) => {
    const { setCompanyId, setLoading, setError } = useAddCompanyContext();

    const methods = useForm<AddCompanyFormDto>({
        defaultValues: {
            name: '',
            address: '',
            responsibleFio: '',
            phone: '',
            inn: '',
        },
    });

    const handleSubmit = async (formData: AddCompanyFormDto) => {
        try {
            setLoading(true);
            const response = await companiesService.add(mapToAddCompanyDto(formData));
            setCompanyId(response.data.data); // Update the companyId in context
            onNext(); // Proceed to the next tab
        } catch (e: any) {
            console.error('Error adding company:', e);
            setError(e.response?.data?.message || 'Ошибка');
        } finally {
            setLoading(false);
        }
    };

    const onSubmitError = (errors: FieldErrors<AddCompanyFormDto>) => {
        const errorMessage = Array.from(new Set(Object.values(errors)
            .map((error) => error.message || 'Invalid field')))
            .join('\n');
        setError('Исправьте ошибки ввода');
    }

    return (
        <FormProvider {...methods}>
            <Box component="form" noValidate>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <Typography variant="h6" mb={1} textAlign="left">
                                Общая информация
                            </Typography>
                            <Stack spacing={2} direction="column">
                                <TextFieldFormControl
                                    name="name"
                                    label="Название компании"
                                    required
                                    maxLength={50}
                                />
                                <TextFieldFormControl
                                    name="address"
                                    label="Адрес компании"
                                    required
                                    maxLength={1000}
                                />
                                <TextFieldFormControl
                                    name="responsibleFio"
                                    label="Ответственное лицо"
                                    required
                                    maxLength={200}
                                />
                                <Box display="flex">
                                    <TextFieldFormControl
                                        name="phone"
                                        label="Телефон"
                                        required
                                        maxLength={20}
                                        style={{ marginRight: 10 }}
                                    />
                                    <TextFieldFormControl
                                        name="inn"
                                        label="ИНН"
                                        required
                                        maxLength={50}
                                    />
                                </Box>
                            </Stack>
                        </FormGroup>
                    </Grid>
                </Grid>
                <TmFullButton
                    sx={{ marginTop: 2 }}
                    onClick={methods.handleSubmit(handleSubmit, onSubmitError)}
                >
                    Далее
                </TmFullButton>
            </Box>
        </FormProvider>
    );
};
