/**
 * Defines the roles in the system.
 */
export enum RoleType {
    // Не определено
    Undefined = "undefined",

    // Администратор
    Admin = "admin",

    // Диспетчер
    Dispatcher = "dispatcher",

    // Медицинский работник
    Medic = "medic",

    // Водитель
    Driver = "driver",
}
