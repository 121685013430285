import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TmCameraAdd from '../../../icons/TmCameraAdd';

interface ImageUploadWithPreviewProps {
    onFileChange: (file: File | null) => void;
    accept?: string;
    required?: boolean;
    error?: string;
    buttonClassName?: string;
    textColor?: string;
    file?: File | null;
    url?: string | null;
}

const ImageUploadWithPreview: React.FC<ImageUploadWithPreviewProps> = ({
    onFileChange,
    accept = 'image/*',
    required = false,
    error,
    buttonClassName,
    textColor = '#9588e8',
    file,
    url
}) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(file || null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    useEffect(() => {
        // Update selected file when prop changes
        setSelectedFile(file || null);
    }, [file]);

    useEffect(() => {
        // Create and cleanup object URL when selectedFile changes
        if (selectedFile) {
            const objectUrl = URL.createObjectURL(selectedFile);
            setPreviewUrl(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        } else {
            setPreviewUrl(url || null);
        }
    }, [selectedFile, url]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selected = event.target.files ? event.target.files[0] : null;
        setSelectedFile(selected);
        onFileChange(selected);
    };

    const handleRemoveImage = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent event bubbling
        setSelectedFile(null);
        onFileChange(null);
    };

    return (
        <Box
            sx={{
                border: '1px',
                borderRadius: '32.5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '195px',
                height: '152px',
                backgroundColor: '#F2F2F2',
                position: 'relative'
            }}
        >
            {!previewUrl ? (
                <>
                    <input
                        id="image-upload-input"
                        type="file"
                        accept={accept}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="image-upload-input">
                        <IconButton
                            component="span"
                            className={buttonClassName}
                        >
                            <TmCameraAdd fontSize="large" fill="none" stroke={textColor} />
                            <Typography component="div" sx={{ ml: 1, color: textColor }}>
                                Загрузить фото
                            </Typography>
                        </IconButton>
                    </label>
                </>
            ) : (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                    <img
                        src={previewUrl}
                        alt="Preview"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '32.5px'
                        }}
                    />
                    <IconButton
                        onClick={handleRemoveImage}
                        aria-label="Remove image"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backgroundColor: 'white',
                            p: '4px',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            }
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Box>
            )}
            {error && (
                <Typography color="error" variant="body2" sx={{ position: 'absolute', bottom: '-20px' }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default ImageUploadWithPreview;