import { Backdrop, Box, Divider, Fade, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import { AddCircle, ModeEdit } from "@mui/icons-material";
import { DriverDto } from "../../../models/drivers/driverDto";
import { useState } from "react";
import AddCertificateDialog from "../AddCertificateDialog";
import { EditHealthCertificateFormDto, mapToEditHealthCertificateFormDto } from "../../../models/healthCertificates/editHealthCertificateFormDto";
import { healthCertificateService } from "../../../services/healthCertificateService";
import Spinner from "../../common/Spinner";
import ErrorAlert from "../../common/ErrorAlert";
import { mapToAddHealthCertificate } from "../../../models/healthCertificates/editHealthCertificateDto";
import ImagePreview from "../../common/image/ImagePreview";
import { CategoryType } from "../../../models/enums/categoryType";
import { PermissionType } from "../../../models/enums/permissionType";
import { ShowForPermission } from "../../common/permissions/ShowForPermission";
import { DateUtils } from "../../../helpers/dateUtils";
import AcceptLabel from "../../common/labels/AcceptLabel";
import DenyLabel from "../../common/labels/DenyLabel";
import TmPencil from "../../../icons/TmPencil";
import TmPlus from "../../../icons/TmPlus";
import { RoleType } from "../../../models/enums/roleType";
import { ShowForRoles } from "../../common/permissions/ShowForRoles";

interface ViewMedicalDataTabProps {
    driver: DriverDto | undefined;
    onEditClick: () => void;
    onRefresh?: () => void,
}

export const ViewMedicalDataTab: React.FC<ViewMedicalDataTabProps> = ({ driver, onEditClick, onRefresh }) => {
    const [addCertificateDialogOpen, setAddCertificateDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState("false");
    const [healthCertificates, setHealthCertificates] = useState<EditHealthCertificateFormDto[]>(driver?.healthCertificates.map(mapToEditHealthCertificateFormDto) || []);

    const handleImageClick = (value: string) => {
        setModalImageUrl(value);
        setOpenImageModal(true);
    };

    const handleAddCertificate = (data: EditHealthCertificateFormDto) => {
        setLoading(true)
        healthCertificateService.add(mapToAddHealthCertificate(data))
            .then(r => {
                data.id = r.data.data;
                setHealthCertificates([...healthCertificates, data]);
                setAddCertificateDialogOpen(false)

                if (onRefresh) {
                    onRefresh();
                }
            })
            .catch(e => {
                console.error(e);
                setError(e.response.data?.message ?? e.message)
                setShowError(true);
            })
            .finally(() => setLoading(false));
    };

    const handleRemoveCertificate = (id: number) => {
        setLoading(true)
        healthCertificateService.delete(id)
            .then(r => {
                setHealthCertificates((prevCertificates) =>
                    prevCertificates.filter((certificate) => certificate.id !== id)
                );
                if (onRefresh) {
                    onRefresh();
                }
            })
            .catch(e => {
                console.error(e);
                setError(e.response.data?.message ?? e.message)
                setShowError(true);
            })
            .finally(() => setLoading(false));
    }

    return (
        <div>
            {loading
                ? <Spinner />
                : (
                    <div>
                        <Box p={0.5}>
                            <Grid container px={3} pt={1} pb={1} mt={1} className="gbr">
                                <Grid item xs={12} md={3}>
                                    <Typography className="ltb">Табельный №&nbsp;</Typography>
                                    <Typography className="rtb">{driver?.employeeNumber}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography className="ltb">Должность:&nbsp;</Typography>
                                    <Typography className="rtb">Водитель</Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography className="ltb">Статус:&nbsp;</Typography>
                                    <Typography className="rtb">{driver?.user?.isDismissed ? <DenyLabel>Уволен</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}</Typography>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Edit}>
                                        <IconButton className="list-item" sx={{ px: "20px" }} onClick={onEditClick}>
                                            <TmPencil fill="none" stroke="white" />&nbsp;&nbsp;
                                            <Typography fontWeight="bold">
                                                Редактировать
                                            </Typography>
                                        </IconButton>
                                    </ShowForPermission>
                                </Grid>
                            </Grid>

                            {/* Справки */}
                            <Grid container spacing={2} alignItems="flex-start" pl={0} pt={2}>
                                <Grid item xs={12} md={6}>
                                    <Box className="gbr">
                                        <Typography p={2} variant="h6" fontWeight='bold'>Справки</Typography>

                                        <ShowForRoles roles={[RoleType.Admin]}>
                                            <IconButton className="list-item" sx={{ ml: "20px", pr: "15px" }} onClick={(e) => setAddCertificateDialogOpen(true)}>
                                                <TmPlus fill="none" stroke="white" />
                                                <Typography fontWeight="bold">
                                                    &nbsp;Добавить справку
                                                </Typography>
                                            </IconButton>
                                        </ShowForRoles>

                                        <Grid container spacing={2} p={2}>
                                            {
                                                healthCertificates.length > 0
                                                    ? healthCertificates.map((h, i) => (
                                                        <Grid item xs={6} key={i}>
                                                            {(h.certificateFile || h.certificateUrl) && (
                                                                <ImagePreview
                                                                    file={h.certificateFile}
                                                                    url={h.certificateUrl}
                                                                    altText="Health Certificate"
                                                                    onClick={() => handleImageClick(h.certificateUrl!)}
                                                                    onRemove={() => handleRemoveCertificate(h.id!)}
                                                                />
                                                            )}
                                                            <Typography variant="body2" fontWeight="bold" sx={{ mt: 1 }}>{h.type?.name}</Typography>
                                                            <Typography variant="body2" className="ltb">Срок действия до:</Typography>&nbsp;
                                                            <Typography variant="body2" className="rtb">{h.validTo ? DateUtils.formatDate(h.validTo) : null}</Typography>
                                                        </Grid>
                                                    ))
                                                    : (
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1">{"<Нет справок>"}</Typography>
                                                        </Grid>
                                                    )
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className="gbr" p={2}>
                                        <Typography p={2} variant="h6" fontWeight="bold">Норма медицинских показателей</Typography>
                                        <Box className="grey-block" p={2}>
                                            <Grid container spacing={2} px={2} pb={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" fontWeight="bold">Артериальное давление</Typography>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" className="grey">Мин.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.upperPressureMin}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2">Макс.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.upperPressureMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" fontWeight="bold">Венозное давление</Typography>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" className="grey">Мин.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.lowerPressureMin}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" className="grey">Макс.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.lowerPressureMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={2} pt={2}>

                                            <Grid item xs={6}>
                                                <Box className="grey-block" p={2}>
                                                    <Typography variant="subtitle1" fontWeight="bold">Пульс</Typography>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" className="grey">Мин.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.pulseMin}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" className="grey">Макс.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.pulseMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Box className="grey-block" p={2}>
                                                    <Typography variant="subtitle1" fontWeight="bold">Температура</Typography>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" className="grey">Макс.</Typography>
                                                            <Typography variant="h5" fontWeight="bold">{driver?.defaultIndicators.temperatureMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                            </Grid>
                                        </Grid>

                                    </Box>
                                </Grid>
                            </Grid>

                            <AddCertificateDialog
                                driverId={driver!.id!}
                                open={addCertificateDialogOpen}
                                onClose={() => setAddCertificateDialogOpen(false)}
                                onAddCertificate={handleAddCertificate}
                            />
                            {showError && (
                                <ErrorAlert
                                    message={error}
                                    onClose={() => setShowError(false)}
                                    autoCloseDuration={10000}
                                />
                            )}
                        </Box >
                        <Modal
                            className='modal-img'
                            open={openImageModal}
                            onClose={() => setOpenImageModal(false)}
                            closeAfterTransition
                        >
                            <img
                                src={modalImageUrl}
                                alt='Health Certificate'
                                style={{ maxHeight: "90%", maxWidth: "90%" }}
                            />
                        </Modal>
                    </div>
                )}
        </div>
    );
};