export enum MedicalCheckupResult {

    // Новый
    New = "New",

    // Допуск
    Approved = "Approved",

    // Недопуск
    Rejected = "Rejected",

    // Повторный
    Repeated = "Repeated"
}

export const MedicalCheckupResultUtil = {
    getStringValue: (result: MedicalCheckupResult | null): string => {
        switch (result) {
            case MedicalCheckupResult.New:
                return "Новый";
            case MedicalCheckupResult.Approved:
                return "Допуск";
            case MedicalCheckupResult.Rejected:
                return "Недопуск";
            case MedicalCheckupResult.Repeated:
                return "Повторный";
            default:
                return "Неизвестно";
        }
    }
}
