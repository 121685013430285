import { CompanyShortDto } from "../companies/companyShortDto";

export interface RegisterDto {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    middleName: string;
    company: CompanyShortDto | null;
    roleId: number | null;
}

export const emptyRegisterDto: RegisterDto = {
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    middleName: '',
    company: null,
    roleId: null,
}