import React from 'react';
import { Box, Typography } from '@mui/material';
import TmEllipses from '../../../icons/TmEllipses';

const TelemedLabel: React.FC = ({ }) => {
  return (
    <Box
      component="span"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="#10BFFF"
      sx={{
        width: '112px',
        height: '37px',
        fontWeight: 'bold',
        backgroundColor: "#7ED2F133",
        borderRadius: "100px",
      }}>
      <TmEllipses fill="none" />
      <Typography component="span" variant="body1" sx={{ fontWeight: 'bold', ml: 0.5 }} fontSize={'16px'}>Telemed</Typography>
    </Box>
  );
}

export default TelemedLabel;
