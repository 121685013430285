import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { MedicalCheckupResult, MedicalCheckupResultUtil } from '../../models/enums/medicalCheckupResult';

interface MedicalCheckupResultSelectProps {
    name: string;
    value: string | undefined;
    onChange: (event: SelectChangeEvent<string>) => void;
}

const MedicalCheckupResultSelect: React.FC<MedicalCheckupResultSelectProps> = ({ name, value, onChange }) => {
    return (
        <FormControl variant="outlined" margin="dense" size="small" style={{ width: 200, marginRight: 10 }}>
            <InputLabel id="medical-checkup-result-label">Результат</InputLabel>
            <Select
                labelId="medical-checkup-result-label"
                id="medical-checkup-result-select"
                name={name}
                value={value || ''}
                label="Результат"
                onChange={onChange}
                sx={{borderRadius: "10px",}}
            >
                {Object.values(MedicalCheckupResult).map((result) => (
                    <MenuItem key={result} value={result}>
                        {MedicalCheckupResultUtil.getStringValue(result)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MedicalCheckupResultSelect;
