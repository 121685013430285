import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { ReactNode, useState } from "react";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from "react-router-dom";
import ErrorAlert from '../common/ErrorAlert';
import { authService } from '../../services/authService';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import TmFullLoadingButton from '../common/buttons/TmFullLoadingButton';
import TmClosedEye from '../../icons/TmClosedEye';
import TmEye from '../../icons/TmEye';
import { ROUTES } from '../../constants/routes';

export const Login = () => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors }, } = useForm({
        mode: 'onChange',
    });
    const [error, setError] = useState('');    
    const [password, setPassword] = useState({ value: "", error: "" });

    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = (data: any) => {
        setLoading(true);
        authService.login(data.email, data.password)
            .then(t => {
                if (t.success) {
                    const searchParams = new URLSearchParams(location.search);
                    const returnUrl = searchParams.get('returnUrl');
                    navigate(returnUrl || ROUTES.drivers.list);
                } else {
                    console.log(t);
                    showAlert(t.message);
                }
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoading(false));
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
    }

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const classes = {
        formLabel: {
            color: '#000',
            '&.Mui-focused': {
                color: '#000'
            }
        }
    }

    return (
        <div>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <TextField
                    fullWidth
                    label="Электронная почта"
                    type="email"
                    margin="normal"
                    {...register('email', {
                        required: 'Электронная почта не заполнена',
                        minLength: {
                            value: 6,
                            message: 'Электронная почта должна быть больше 6ти символов',
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Невалидная электронная почта',
                        },
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message as ReactNode}
                />

                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                        {errors.password ? <Box color="#d32f2f">Пароль</Box> : <Box>Пароль</Box>}
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        {...register('password', {
                            required: 'Пароль не заполнен',
                            minLength: {
                                value: 6,
                                message: 'Пароль должен быть больше 6ти символов',
                            },
                        })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <TmEye fill="none" stroke="#a4a5b3" /> : <TmClosedEye fill="none" stroke="#a4a5b3" />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Пароль"
                        error={Boolean(errors.password)}
                    />
                    {!!errors.password && (
                        <FormHelperText error id="password-error">
                            Пароль не заполнен
                        </FormHelperText>
                    )}
                </FormControl>

                <TmFullLoadingButton
                    type="submit"
                    sx={{ mt: 3, mb: 2 }}
                    loading={loading}
                    loadingPosition="start"
                    width="100%"
                >
                    Войти
                </TmFullLoadingButton>
            </Box>
            {error && (
                <ErrorAlert
                    message={error}
                    onClose={() => setError('')}
                />
            )}
        </div>
    );
}