import React, { forwardRef, useEffect, useState } from 'react';
import { Autocomplete, Icon, IconButton, TextField } from '@mui/material';
import { companiesService } from '../../services/companiesService';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import { OpenWithSharp } from '@mui/icons-material';
import TmBell from '../../icons/TmBell';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import TmChevronUp from '../../icons/TmChevronUp';
import TmCross from '../../icons/TmCross';

interface CompanyAutocompleteProps {
    company: CompanyShortDto | null; // Required {id,name} object, not just id, to not query name from backend
    onCompanyChange: (newCompany: CompanyShortDto) => void;
    label?: string;
    style?: React.CSSProperties;
}

const CompanyAutocomplete = forwardRef<HTMLDivElement, CompanyAutocompleteProps>(({ company, onCompanyChange, label = "Клиент", style = {} }, ref) => {
        const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
        const [initialCompanies, setInitialCompanies] = useState<CompanyShortDto[]>([]);

        useEffect(() => {
            companiesService.search({
                name: '',
                page: 0,
                pageSize: 10,
            })
            .then(response => {
                setCompanies(response.data.data.items);
                setInitialCompanies(response.data.data.items); // Save the initial list of companies
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
            });
        }, []);

        const handleInputChange = (_: React.SyntheticEvent, newInputValue: string) => {
            if (newInputValue.length >= 3) {
                companiesService.search({
                    name: newInputValue,
                    page: 0,
                    pageSize: 10,
                })
                    .then(response => {
                        setCompanies(response.data.data.items.length > 0 ? response.data.data.items : initialCompanies);
                    })
                    .catch(error => {
                        console.error('Error fetching companies:', error);
                    });
            } else {
                setCompanies(initialCompanies); // Revert to initial list if input is cleared or less than 3 chars
            }
        };

        const handleChange = (_: React.SyntheticEvent, newValue: CompanyShortDto | null) => {
            onCompanyChange(newValue!);
        };

        return (
            <Autocomplete
                //Пока убрал, т.к. у комбобоксов TextField и Select проблемы с отображением иконки
                //Надо сначала с ними разобраться
                // popupIcon={ <TmChevronUp fill="none" stroke="#cccdd5" /> } 
                // clearIcon={<TmCross fill="none" stroke="#cccdd5" />}
                options={companies}
                getOptionLabel={(option) => option.name}
                value={company}
                onChange={handleChange}
                onInputChange={handleInputChange}
                noOptionsText="Не найдено"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                ref={ref}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={style}
                        placeholder="Введите от 3х букв"
                    />
                )}
            />
        );
    }
);

export default CompanyAutocomplete;
