import React from 'react';
import { Avatar, Box, SxProps, Theme, Typography } from '@mui/material';
import { MedicalServicesOutlined } from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';

interface TmSignUpLogoProps {
  sx?: SxProps<Theme>;
}

const TmSignUpLogo: React.FC<TmSignUpLogoProps> = ({ sx }) => {
  return (
    <Box display="flex" textAlign="center" py={1} sx={{...sx}}>
        <Avatar sx={{ bgcolor: deepPurple[500] }}><MedicalServicesOutlined /></Avatar>
        <Typography variant='h5' ml={1} mt={0.5} color="#481a84">
            Медосмотры
        </Typography>
    </Box>
  );
}

export default TmSignUpLogo;
