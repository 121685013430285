import React from 'react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

interface TextAreaFormControlProps {
    name: string;
    label: string;
    required?: boolean;
    style?: React.CSSProperties;
    rows?: number;
    maxLength?: number;
}

export const TextAreaFormControl: React.FC<TextAreaFormControlProps> = ({ name, label, required = false, style, rows = 4, maxLength, ...otherProps }) => {
    const { control, formState: { errors } } = useFormContext();

    const getNestedError = (name: string): FieldErrors | undefined => {
        return name.split('.').reduce((acc: any, curr: string) => {
            return acc && acc[curr] ? acc[curr] : undefined;
        }, errors);
    };

    const validateNotOnlySpaces = (value: string) => {
        return value.trim().length > 0 || 'Поле не может содержать только пробелы';
    };

    const fieldError = getNestedError(name);
    const isError = Boolean(fieldError);

    const rules = {
        required: required ? 'Поле обязательно для заполнения' : false,
        ...(required && { validate: validateNotOnlySpaces }),
        maxLength: maxLength ? { value: maxLength, message: `Максимальная длина ${maxLength} символов` } : undefined,
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...field}
                    label={label}
                    multiline
                    rows={rows}
                    error={isError}
                    helperText={isError ? (fieldError as any)?.message : ''}
                    required={required}
                    style={style}
                    fullWidth
                    {...otherProps}
                />
            )}
        />
    );
};
