import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material"
import TmPencil from "../../../icons/TmPencil"
import { CategoryType } from "../../../models/enums/categoryType"
import { PermissionType } from "../../../models/enums/permissionType"
import { ShowForPermission } from "../../common/permissions/ShowForPermission"
import { useEffect, useState } from "react"
import { EditDepartmentDialog } from "../edit/EditDepartmentDialog"
import { useNavigate, useParams } from "react-router-dom"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { departmentsService } from "../../../services/departmentsService"
import { ROUTES } from "../../../constants/routes"
import Spinner from "../../common/Spinner"
import { DepartmentDetailsDto } from "../../../models/departments/departmentDetailsDto"
import { UserUtils } from "../../../helpers/userUtils"

export const DepartmentDetails: React.FC = () => {
    const [department, setDepartment] = useState<DepartmentDetailsDto>();
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();

    const fetchDepartmentData = async () => {
        const departmentId = Number(params.id);
        try {
            const response = await departmentsService.getById(departmentId);
            setDepartment(response.data.data);
        } catch (e: any) {
            console.error(e);
            if (e.response.status === 404) {
                navigate(ROUTES.auth.notFound);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch department data when the component is mounted
        fetchDepartmentData();
    }, [params.id]);

    function handleEditPopupToggle(): void {
        setEditPopupOpen(!isEditPopupOpen);
    }

    return (
        <Box>
            {loading
                ? <Spinner />
                : (<Box>
                    <Grid container alignItems="center" justifyContent="space-between">

                        <Grid item xs={2}>
                            <IconButton onClick={() => navigate(-1)} sx={{ my: 2 }}>
                                <Avatar sx={{ backgroundColor: "white", border: 1, borderColor: "#9588e8" }}>
                                    <ChevronLeftIcon sx={{ color: '#9588e8' }} />
                                </Avatar>
                            </IconButton>
                        </Grid>

                        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h6" align="center">Данные об объекте</Typography>
                        </Grid>

                        <Grid item xs={2}>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={0.5}>

                        <Grid item xs={12}>
                            <Box className="gbr" px={2} pb={2} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <Grid container>
                                    <Grid item xs={10} pt={2}>
                                        <Typography variant="h5" fontWeight="bold">
                                            Общая информация
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} pt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <ShowForPermission catalog={CategoryType.Departments} permission={PermissionType.Edit}>
                                            <IconButton className="list-item" sx={{ px: '20px' }} onClick={handleEditPopupToggle}>
                                                <TmPencil fill="none" stroke="white" />
                                                &nbsp;&nbsp;
                                                <Typography fontWeight="bold">Редактировать</Typography>
                                            </IconButton>
                                        </ShowForPermission>
                                    </Grid>
                                </Grid>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Box>
                                            <Typography className="ltb" variant="body1" mr={1}>Название</Typography>
                                            <Typography className="rtb">{department?.name}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="ltb" variant="body1" mr={1}>Адрес</Typography>
                                            <Typography className="rtb">{department?.address}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="ltb" variant="body1" mb={1}>Терминалы</Typography>
                                            <Grid container textAlign="center">
                                                {department?.terminals && department?.terminals.length > 0
                                                    ? department?.terminals.map((d, index) => (
                                                        <Box key={index} className="vbr" px={2} py={1} m={0.5}>
                                                            <Typography variant="body1">{d.number}</Typography>
                                                        </Box>
                                                    ))
                                                    : <Typography variant="body1">{"<Нет терминалов>"}</Typography>}
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Typography className="ltb" variant="body1" mb={1}>Диспетчеры</Typography>
                                            <Grid container textAlign="center">
                                                {department?.dispatchers && department?.dispatchers.length > 0
                                                    ? department?.dispatchers.map((d, index) => (
                                                        <Box key={index} className="vbr" px={2} py={1} m={0.5}>
                                                            <Typography variant="body1" fontWeight="bold">{UserUtils.getFio(d)}</Typography>
                                                            <Typography variant="body1">{d.phone ?? "<Нет телефона>"}</Typography>
                                                        </Box>
                                                    ))
                                                    : <Typography variant="body1">{"<Нет диспетчеров>"}</Typography>}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <EditDepartmentDialog onClose={handleEditPopupToggle} open={isEditPopupOpen} isAdding={false} onSave={fetchDepartmentData} department={department} companyId={department?.companyId} />
                </Box>)}
        </Box>
    );
};