import React from 'react';
import { Typography, Link } from '@mui/material';
import TmDeny from '../../../icons/TmDeny';

interface DenyButtonProps {
    onClick: () => void;
    children: string;
  }

  
const DenyButton: React.FC<DenyButtonProps> = ({ onClick, children }) => {
  return (
    <Link href="#"
        py={1} px={2} sx={{ color: "white", backgroundColor: "#ec4a71"}} 
        display="inline-flex"
        fontWeight="bold"
        underline="none"
        border={1}
        borderRadius={25}
        borderColor="#ec4a71"
    onClick={onClick}><TmDeny stroke="white" /><Typography fontWeight="bold">&nbsp;&nbsp;{children}</Typography></Link>
  );
}

export default DenyButton;
