import { Box, Grid, IconButton, Typography } from "@mui/material"
import TmPencil from "../../../icons/TmPencil"
import { CategoryType } from "../../../models/enums/categoryType"
import { PermissionType } from "../../../models/enums/permissionType"
import { ShowForPermission } from "../../common/permissions/ShowForPermission"
import { useState } from "react"
import { useCompanyDetailsContext } from "./context/CompanyDetailsContextProvider"
import { EditCompanyMainInfoDialog } from "./dialogs/EditCompanyMainInfoDialog"
import { DepartmentsAndDispatchersTable } from "./DepartmentsAndDispatchersTable"

export const CompanyMainInfoTab: React.FC = () => {
    const { company, setCompany } = useCompanyDetailsContext();
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);

    function handleEditPopupToggle(): void {
        setEditPopupOpen(!isEditPopupOpen);
    }

    return (
        <Box>
            
            <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12}>
                    <Box className="gbr" px={2} pb={2} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Grid container>
                            <Grid item xs={10} pt={2}>
                                <Typography variant="h5" fontWeight="bold">
                                    Общая информация
                                </Typography>
                            </Grid>
                            <Grid item xs={2} pt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ShowForPermission catalog={CategoryType.Companies} permission={PermissionType.Edit}>
                                    <IconButton className="list-item" sx={{ px: '20px' }} onClick={handleEditPopupToggle}>
                                        <TmPencil fill="none" stroke="white" />
                                        &nbsp;&nbsp;
                                        <Typography fontWeight="bold">Редактировать</Typography>
                                    </IconButton>
                                </ShowForPermission>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Название организации</Typography>
                                    <Typography className="rtb">{company?.name}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Адрес</Typography>
                                    <Typography className="rtb">{company?.address}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>ИНН</Typography>
                                    <Typography className="rtb">{company?.inn}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Ответственное лицо</Typography>
                                    <Typography className="rtb">{company?.responsibleFio}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Телефон</Typography>
                                    <Typography className="rtb">{company?.phone}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Терминалы</Typography>
                                    <Typography className="rtb">{company?.terminals.map(t => t.name).join(', ')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography pb={1} variant="h5" fontWeight="bold">
                        Объекты и диспетчеры
                    </Typography>
                    <Box className="gbr" px={2}>

                        <Box>
                            <DepartmentsAndDispatchersTable/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <EditCompanyMainInfoDialog open={isEditPopupOpen} company={company!} onClose={handleEditPopupToggle} onSave={setCompany}/>
        </Box>
    );
};