import { useLayoutEffect } from "react";
import {Layout} from "../../components/common/Layout"
import { CompaniesTable } from "../../components/companies/CompaniesTable";

export const CompaniesPage = () => {

    const title = "Клиенты";
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "white"
        document.body.style.backgroundImage = "";
   });
    return (
        <Layout title={title}>
            <CompaniesTable/>
        </Layout>
    )
}