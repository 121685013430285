import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, Autocomplete, Typography, Paper, Chip } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { medicalCheckupsService } from "../../services/medicalCheckupsService";
import ErrorAlert from "../common/ErrorAlert";
import React from "react";
import { RejectReasonDto } from "../../models/medicalCheckups/rejectReasonDto";
import TmFullButton from "../common/buttons/TmFullButton";
import TmEmptyButton from "../common/buttons/TmEmptyButton";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface NotAllowedDialogProps {
    medicalCheckupId: number;
    open: boolean;
    onSubmit: (selectedReasons: number[], comment: string) => void;
    onClose: () => void;
}

export const NotAllowedDialog: React.FC<NotAllowedDialogProps> = ({ open, onSubmit, onClose }) => {
    const [notAllowedReasons, setNotAllowedReasons] = useState<RejectReasonDto[]>([]);
    const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            medicalCheckupsService.getRejectReasons()
                .then(response => {
                    const reasons = response.data.data;
                    setNotAllowedReasons(reasons);
                })
                .catch(console.error);
        }
    }, [open]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: "#9588e8" }} />;

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': { 
                    height: '80vh',
                    maxHeight: '90vh',
                }
            }}
            >
            <DialogTitle variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>{"Недопуск сотрудника"}</DialogTitle>
            <Typography textAlign="center" fontWeight="600">Укажите причины недопуска сотрудника</Typography>
            <DialogContent>
                <Typography fontWeight="bold">Причина недопуска</Typography>
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={notAllowedReasons}
                    groupBy={(option) => option.group}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderGroup={(params) => (
                        <li key={params.key} style={{ margin: 0, padding: '2px 0' }}>
                            <Typography sx={{ fontWeight: 'bold', paddingLeft: '16px' }}>
                                {params.group}
                            </Typography>
                            <ul style={{ padding: 0, margin: 0 }}>
                                {params.children}
                            </ul>
                        </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        value.length <= 3
                            ? value.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                            : [<Chip key="more" label={`${value.length} выбрано`} />]
                    }
                    ListboxProps={{
                        style: {
                            maxHeight: '40vh',
                        },
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li
                            {...props}
                            style={{
                                ...props.style,
                                margin: 0,
                                padding: '1px 1px',
                            }}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                sx={{ fontWeight: "bold" }}
                            />
                            {option.name}
                        </li>
                    )}
                    style={{ width: '100%', marginTop: 5 }}
                    renderInput={(params) => (
                        <TextField 
                        {...params} 
                        placeholder="Выбрать причину" 
                        size="small"
                        />
                    )}
                    onChange={(event, newValue) => {
                        setSelectedReasons(newValue.map(item => item.id));
                    }}
                />

                <Typography fontWeight="bold" pt={2}>Комментарий</Typography>
                <Typography>Укажите дополнительные причины недопуска, которых нет в списке</Typography>
                <TextField
                    multiline
                    rows={8}
                    autoFocus
                    margin="dense"
                    id="description"
                    placeholder="Напишите причину"
                    type="text"
                    variant="outlined"
                    value={comment}
                    onChange={(event: { target: { value: SetStateAction<string>; }; }) => setComment(event.target.value)}
                    style={{ width: '100%' }}
                //helperText={"Укажите дополнительные причины недопуска, которых нет в списке"}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <TmEmptyButton onClick={onClose} width="45%">
                    Отмена
                </TmEmptyButton>
                <TmFullButton onClick={() => onSubmit(selectedReasons, comment)} width="45%">
                    Сохранить
                </TmFullButton>
            </DialogActions>
            {error && (
                <ErrorAlert
                    message={error}
                    onClose={() => setError('')}
                />
            )}
        </Dialog>
    );
};