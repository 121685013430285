import { Layout } from '../../components/common/Layout';
import { CompanyDetails } from '../../components/companies/details/CompanyDetails';

  export const CompanyDetailsPage = () => {

    const title = "Клиенты";

    return (
        <Layout title={title}>
            <CompanyDetails/>
        </Layout>
    )
}