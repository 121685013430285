import React, { useState } from 'react';
import { Box, Typography, Backdrop } from '@mui/material';
import StyledTabHidden from '../../../common/tabs/StyledTabHidden';
import StyledTabs from '../../../common/tabs/StyledTabs';
import { AddCompanyTab } from './AddCompanyTab';
import { AddDepartmentsAndDispatchersTab } from './AddDepartmentsAndDispatchersTab';
import { AddDriversTab } from './AddDriversTab';
import { AddTerminalsTab } from './AddTerminalsTab';
import ErrorAlert from '../../../common/ErrorAlert';
import Spinner from '../../../common/Spinner';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { AddCompanyContextProvider, useAddCompanyContext } from '../context/AddCompanyContextProvider';

interface AddCompanyTabsProps { }

const AddCompanyTabsContent: React.FC = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { error, setError, loading } = useAddCompanyContext();
    const navigate = useNavigate();

    // Handle tab change
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    // Function to navigate to the next tab
    const handleNextTab = () => {
        setCurrentTab((prevTab) => prevTab + 1);
    };

    // Function to navigate to the previous tab
    const handlePrevTab = () => {
        setCurrentTab((prevTab) => prevTab - 1);
    };

    // Function to navigate when the process is done
    const handleDone = (): void => {
        navigate(ROUTES.clients.list);
    };

    return (
        <>
            {error && (
                <ErrorAlert
                    message={error}
                    onClose={() => setError(null)}
                />
            )}
            <Box sx={{ textAlign: 'center', width: '80%', mx: 'auto' }}>
                <Typography variant='h5' sx={{ mb: -3, mt: 3, pb: 1 }}>
                    Добавление клиента
                </Typography>

                <StyledTabs value={currentTab} onChange={handleTabChange} sx={{ textAlign: 'center', width: '47%', mx: 'auto' }}>
                    <StyledTabHidden label='' />
                    <StyledTabHidden label='' />
                    <StyledTabHidden label='' />
                    <StyledTabHidden label='' />
                </StyledTabs>

                <Box sx={{ padding: 3 }}>
                    {currentTab === 0 && <AddCompanyTab onNext={handleNextTab} />}
                    {currentTab === 1 && <AddDepartmentsAndDispatchersTab onNext={handleNextTab} />}
                    {currentTab === 2 && <AddDriversTab onNext={handleNextTab} onPrevious={handlePrevTab} />}
                    {currentTab === 3 && <AddTerminalsTab onDone={handleDone} onPrevious={handlePrevTab} />}
                </Box>
                
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <Spinner />
                </Backdrop>
            </Box>
        </>
    );
};

export const AddCompanyTabs: React.FC<AddCompanyTabsProps> = () => {
    return (
        <AddCompanyContextProvider>
            <AddCompanyTabsContent />
        </AddCompanyContextProvider>
    );
};
