import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogActions, DialogTitle, Grid, TextField, Typography, Checkbox, FormControlLabel, Autocomplete, FormGroup, Stack, IconButton, Backdrop, Chip } from '@mui/material';
import TmFullButton from '../../common/buttons/TmFullButton';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { EditTerminalFormDto, emptyEditTerminalFormDto, mapToAddTerminalDto, mapToEditTerminalDto, mapToEditTerminalFormDto } from '../../../models/terminals/editTerminalFormDto';
import { DepartmentShortDto } from '../../../models/departments/departmentShortDto';
import { CompanyShortDto } from '../../../models/companies/companyShortDto';
import { companiesService } from '../../../services/companiesService';
import { terminalsService } from '../../../services/terminalsService';
import { TerminalDetailsDto } from '../../../models/terminals/terminalDetailsDto';
import ErrorAlert from '../../common/ErrorAlert';
import { TextFieldFormControl } from '../../common/form/TextFieldFormControl';
import { TextAreaFormControl } from '../../common/form/TextAreaFormControl';
import TmCross from '../../../icons/TmCross';
import Spinner from '../../common/Spinner';
import { Terminal } from '../../companies/add/models/terminal';
import { departmentsService } from '../../../services/departmentsService';

interface EditTerminalModalProps {
    open: boolean;
    onClose: () => void;
    onRefresh?: () => void;
    onAddingSuccess?: (terminal: Terminal) => void;
    isAdding: boolean;
    terminal?: TerminalDetailsDto;
    companyId?: number;
}

export const EditTerminalModal: React.FC<EditTerminalModalProps> = ({ open, onClose, onRefresh, onAddingSuccess, isAdding, terminal, companyId }) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState<DepartmentShortDto[]>([]);
    const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
    const methods = useForm<EditTerminalFormDto>({
        defaultValues: terminal ? mapToEditTerminalFormDto(terminal) : emptyEditTerminalFormDto(companyId),
    });

    useEffect(() => {
        if (open) {
            const defaultValues = isAdding ? emptyEditTerminalFormDto(companyId) : mapToEditTerminalFormDto(terminal!);
            methods.reset(defaultValues);
        }
    }, [open, isAdding, terminal, methods, companyId]);

    useEffect(() => {
        companiesService.search({
            name: companyId ? undefined : '',
            companyIds: companyId ? [companyId] : undefined,
            page: 0,
            pageSize: 999,
        })
            .then(r => setCompanies(r.data.data.items))
            .catch(e => console.log(e));
    }, []);

    const watchedCompanyIds = methods.watch("companyIds");
    useEffect(() => {
        if (watchedCompanyIds && watchedCompanyIds.length > 0) {
            departmentsService.getByCompanyIds(watchedCompanyIds)
                .then(r => {
                    setDepartments(r.data.data);

                    // Keep only previously selected departments that are still valid
                    const currentDepartmentIds = methods.getValues('departmentIds');
                    const validDepartmentIds = r.data.data
                        .filter(dept => currentDepartmentIds.includes(dept.id))
                        .map(dept => dept.id);

                    methods.setValue('departmentIds', validDepartmentIds);
                });
        } else {
            setDepartments([]);
            methods.setValue('departmentIds', []);
        }
    }, [watchedCompanyIds, methods]);

    const onSubmit = (formData: EditTerminalFormDto) => {
        setLoading(true);

        const serviceCall = isAdding
            ? terminalsService.add(mapToAddTerminalDto(formData))
            : terminalsService.edit(mapToEditTerminalDto(formData));

        serviceCall
            .then(r => {
                onClose();
                if (onRefresh) {
                    onRefresh();
                }
                if (isAdding && onAddingSuccess) {
                    const addedTerminal = {
                        id: r.data.data,
                        name: formData.name,
                        number: formData.number,
                        address: formData.address,
                        departments: departments.filter(dept => formData.departmentIds.includes(dept.id)).map(dept => ({ id: dept.id, name: dept.name })),
                    };
                    onAddingSuccess(addedTerminal);
                }
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoading(false));
    };

    const onSubmitError = (errors: any) => {
        showAlert("Исправьте ошибки ввода");
        console.log('Form Errors:', errors);
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
    };

    return (
        <Box>
            <FormProvider {...methods}>
                <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "25px" } }}>
                    {error && (
                        <ErrorAlert
                            message={error}
                            onClose={() => setError('')}
                        />
                    )}
                    <DialogTitle variant='h5' sx={{ textAlign: 'center' }}>{isAdding ? "Добавление нового терминала" : "Редактирование терминала"}</DialogTitle>
                    {!error && (<IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            zIndex: 10000
                        }}
                    >
                        <TmCross fill="none" stroke="#cccdd5" />
                    </IconButton>
                    )}
                    <DialogContent>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6'>Общая информация</Typography>
                                        <Stack spacing={2} direction="column">
                                            <TextFieldFormControl
                                                name="name"
                                                label="Название"
                                                required
                                                maxLength={50}
                                            />
                                            <TextFieldFormControl
                                                name="number"
                                                label="Серийный номер"
                                                required
                                                maxLength={50}
                                            />
                                            <TextFieldFormControl
                                                name="address"
                                                label="Адрес"
                                                required
                                                maxLength={1000}
                                            />
                                            {(isAdding || terminal?.version === "v2") &&
                                                <TextFieldFormControl
                                                    name="hardwareId"
                                                    label="HardwareId"
                                                    required
                                                    maxLength={100}
                                                />
                                            }
                                            <TextAreaFormControl
                                                name="description"
                                                label="Описание"
                                                rows={5}
                                                maxLength={1000}
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6'>Клиенты и объекты</Typography>
                                        <Stack spacing={2} direction="column">
                                        <Controller
                                                name='companyIds'
                                                control={methods.control}
                                                defaultValue={[]}
                                                rules={{ required: 'Поле обязательно для заполнения' }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="companyIds"
                                                        options={companyId ? [] : companies} // Render no options if companyId is provided
                                                        getOptionLabel={(item) => (item?.name ?? "")}
                                                        value={companies.filter(company => value?.includes(company.id) ?? false)}
                                                        onChange={(event, newValue) => {
                                                            if (!companyId) { // Only allow changes if companyId is not provided
                                                                const newCompanyIds = newValue.map(company => company.id);
                                                                onChange(newCompanyIds);
                                                            }
                                                        }}
                                                        noOptionsText={companyId ? "" : "Не найдено"}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='Клиенты'
                                                                error={Boolean(methods.formState.errors.companyIds)}
                                                                helperText={methods.formState.errors.companyIds?.message}
                                                                placeholder="Введите от 3х букв"
                                                                required
                                                                disabled={!!companyId} // Disable if companyId is provided
                                                            />
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                    key={option.id}
                                                                />
                                                            ))
                                                        }
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name='departmentIds'
                                                control={methods.control}
                                                defaultValue={[]}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="departmentIds"
                                                        options={departments}
                                                        getOptionLabel={(item) => (item?.name ?? "")}
                                                        value={departments.filter(department => value?.includes(department.id) ?? false)}
                                                        onChange={(event, newValue) => {
                                                            onChange(newValue.map(department => department.id));
                                                        }}
                                                        noOptionsText="Не найдено"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='Объекты'
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props} key={option.id}>
                                                                {option.name}
                                                                <span style={{ color: 'grey', marginLeft: '4px' }}>
                                                                    • {option.company.name}
                                                                </span>
                                                            </li>
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    label={
                                                                        <Typography component="span" sx={{ fontSize: 'inherit' }}>
                                                                            {option.name}
                                                                            <Typography
                                                                                component="span"
                                                                                color="text.secondary"
                                                                                sx={{ ml: 0.5, fontSize: 'inherit' }}
                                                                            >
                                                                                • {option.company.name}
                                                                            </Typography>
                                                                        </Typography>
                                                                    }
                                                                    {...getTagProps({ index })}
                                                                    key={option.id}
                                                                />
                                                            ))
                                                        }
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6' sx={{ mt: 2 }}>Статус</Typography>
                                        <Controller
                                            name='isNotActive'
                                            control={methods.control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={<Checkbox {...field} checked={field.value} />}
                                                    label="Не активен"
                                                />
                                            )}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', paddingBottom: "2em" }}>
                        <TmFullButton onClick={methods.handleSubmit(onSubmit, onSubmitError)} width='45%'>
                            Сохранить
                        </TmFullButton>
                    </DialogActions>
                </Dialog>
            </FormProvider>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={loading}
            >
                <Spinner />
            </Backdrop>
        </Box>
    );
};